import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './auth.scss';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { eyeIcon, handleChangePassword } from '../../../components/common';
import { LoginUser } from '../../../redux/action/Auth';
import PasswordValidation from './PasswordValidation';
import Loader from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../components/Layout/AuthHeader';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const initialValye = {
  password: '',
  // username: '',
  email: '',
};

const Login = (props) => {
  const { t } = useTranslation('auth');
  const language = localStorage.getItem("i18nextLng") ?? "en"
  const history = useHistory();
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.authData);
  const [showPassword, setShowPassword] = useState(false);
  const [expanded, setExpanded] = useState('panel1');
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    // username: Yup.string().required("Full name required!"),
    password: Yup.string()
      .required(t("pass_required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, One Special Case Character',
      ),
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
  });

  useEffect(() => {
    if (loginUser?.login) {
      setTimeout(() => {
        setLoading(false);
        history.push('/verify');
      }, 3000)
    }
  }, [loginUser]);

  const handleChangeAcco = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={
      props.currentLanguage === 'ar'
        ? {
          direction: 'rtl',
        }
        : {
          direction: 'ltr',
        }
    }>
      <AuthHeader title={t("welcome")} description={t("login_description")} signin={t("signin")} signup={t("signup")} />
      <Box>
        <Formik
          initialValues={initialValye}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const data = {
              ...values,
              language: language
            }
            dispatch(LoginUser(data));
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 3000)
          }}
        >
          {({ errors, touched, values, handleChange }) => {
            return (
              <>
                <Form>
                  <div className="login-form form-group">
                    <Grid item container spacing={1} justify="center">
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <Field
                          name="email"
                          type="text"
                          value={values.email}
                          placeholder={t("enter_email")}
                          className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-msg">{errors.email}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                      <div style={{ position: 'relative' }}>
                        <Field
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          placeholder={t("enter_pass")}
                          onChange={handleChangePassword(handleChange)}
                          className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''
                            }`}
                          lang={props.currentLanguage}
                          id="psw"
                        />
                        {eyeIcon(props.currentLanguage, showPassword, setShowPassword)}
                      </div>
                      {errors.password && touched.password ? (
                        <PasswordValidation currentLanguage={props.currentLanguage} expanded={expanded} handleChangeAcco={handleChangeAcco} />
                      ) : null}
                    </Grid>
                    <div onClick={() => history.push("/forgotlink")} align="end" className="forgotpass pointer">
                      {t("forgot_pass")}
                    </div>
                    <Button className="login-btn" type="submit" fullWidth>
                      {loading ? <Loader /> : t("login")}
                    </Button>

                    <div className="divider">
                      <div className="border" />
                      <span className="content">{t("or")}</span>
                      <div className="border" />
                    </div>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      className="header-muhnak"
                    >
                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className="footer-icon">
                                                <img src={Google} alt="google" />
                                                <div>SIGN IN WITH GOOGLE</div>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="footer-icon">
                                                <img src={FB} alt="google" />
                                                <div>SIGN IN WITH FACEBOOK</div>
                                            </Box> */}
                    </Box>
                    <div className="not-member-main" >
                      <span className="not-member">
                        {t("no_member")}{' '}
                        <span onClick={() => history.push('/register')} className="regidter-now">
                          {t("regi_now")}
                        </span>
                      </span>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default Login;
