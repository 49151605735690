import USA from '../../assets/images/usa.svg';
import EMEA from '../../assets/images/emea.svg';
import UK from '../../assets/images/uk.svg';
import { InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const localeList = [
  { image: USA, label: 'English', name: 'English', value: 'en' },
  { image: USA, label: 'French', name: 'French', value: 'fr' },
  { image: UK, label: 'Hindi', name: 'Hindi', value: 'hi' },
  { image: USA, label: 'Bengali', name: 'Bengali', value: 'bn' },
  { image: EMEA, label: 'Arabic', name: 'Arabic', value: 'ar' },
  { image: USA, label: 'Russian', name: 'Russian', value: 'ru' },
  { image: USA, label: 'Chinese', name: 'Chinese', value: 'zh' },
  { image: USA, label: 'Spanish', name: 'Spanish', value: 'es' },
  { image: USA, label: 'Portuguese', name: 'Portuguese', value: 'por' },
  { image: USA, label: 'Telugu', name: 'Telugu', value: 'te' },
  { image: USA, label: 'Turkish', name: 'Turkish', value: 'tr' },
  { image: USA, label: 'Japanese', name: 'Japanese', value: 'ja' },
  { image: USA, label: 'Javanese', name: 'Javanese', value: 'jv' },
  { image: USA, label: 'Korean', name: 'Korean', value: 'ko' },
  { image: USA, label: 'Punjab', name: 'Punjab', value: 'pan' },
];
export const Exchange = ['TASI', 'UAE', 'ADX', 'DFM', 'GX30'];
export const UserService = [
  { name: 'Real time data', value: 'Real time data' },
  { name: 'Investor Recommendations', value: 'Investor Recommendations' },
  { name: 'Trader Recommendations', value: 'Trader Recommendations' },
  { name: 'Email Notification', value: 'Email Notification' },
  { name: 'Mobile SMS notification', value: 'Mobile SMS notification' },
];

export const adminColumn = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'fullName', label: 'Full Name' },
  { id: 'userId', label: 'User Id' },
  { id: 'action', label: 'Actions' },
];

export const marketColunmn = [
  { id: 'country', label: 'Country' },
  { id: 'exchange', label: 'Exchange' },
];

export const marketInvestorColumn = [
  { id: 'country', label: 'Country' },
  { id: 'market', label: 'Market' },
  { id: 'exchange', label: 'Exchange' },
];

export const subServiceColunmn = [
  { id: 'serviceName', label: 'Service name' },
  { id: 'subTerms', label: 'Subscription Terms' },
  { id: 'fees', label: 'Fees' },
];

export const currentLanguages = ({ currentLanguage }) => {
  return currentLanguage === 'ar'
    ? {
      direction: 'rtl',
    }
    : {
      direction: 'ltr',
    };
};

export const base64ToArrayBuffer = (base64) => {
  const imageData = base64.toString()
  if (imageData?.includes("data:image") || imageData?.includes("data:application")) {
    let binary_string;
    if (imageData?.includes("data:image")) {
      binary_string = window.atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    } else {
      binary_string = window.atob(imageData.replace("data:application/octet-stream;base64,", ''));
    }
    let len = new Array(binary_string.length);
    for (let i = 0; i < binary_string.length; i++) {
      len[i] = binary_string.charCodeAt(i);
    }
    let byteArray = new Uint8Array(len);
    let blob = new Blob([byteArray]);
    return blob;
  }
  else {
    return base64;
  }
}

const removeInvalid = (value, lang) => {
  return value?.classList.remove(lang === "en" ? "invalid" : "ar_invalid");
}

const addValid = (value, lang) => {
  return value?.classList.add(lang === "en" ? "valid" : "ar_valid");
}

const removeValid = (value, lang) => {
  return value?.classList.remove(lang === "en" ? "valid" : "ar_valid");
}

const addInvalid = (value, lang) => {
  return value?.classList.add(lang === "en" ? "invalid" : "ar_invalid");
}


export const handleChangePassword = (handleChange) => (event) => {
  const lang = event?.target?.lang
  let myInput = document?.getElementById("psw");
  let letter = document?.getElementById("letter");
  let capital = document?.getElementById("capital");
  let number = document?.getElementById("number");
  let length = document?.getElementById("length");
  let special = document?.getElementById("special");

  // When the user starts to type something inside the password field
  myInput.onkeyup = function () {
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    if (myInput.value.match(lowerCaseLetters)) {
      removeInvalid(letter, lang);
      addValid(letter, lang);
    } else {
      removeValid(letter, lang);
      addInvalid(letter, lang)
    }

    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    if (myInput.value.match(upperCaseLetters)) {
      removeInvalid(capital, lang);
      addValid(capital, lang);
    } else {
      removeValid(capital, lang);
      addInvalid(capital, lang)
    }

    // Validate numbers
    const numbers = /\d/g;
    if (myInput.value.match(numbers)) {
      removeInvalid(number, lang);
      addValid(number, lang);
    } else {
      removeValid(number, lang);
      addInvalid(number, lang)
    }

    // Validate length
    if (myInput.value.length >= 8) {
      removeInvalid(length, lang);
      addValid(length, lang);
    } else {
      removeValid(length, lang);
      addInvalid(length, lang)
    }

    const specialChar = /[ -\/:-@\[-\`{-~]/g;
    if (myInput.value.match(specialChar)) {
      removeInvalid(special, lang);
      addValid(special, lang);
    } else {
      removeValid(special, lang);
      addInvalid(special, lang)
    }
  }
  handleChange(event)
}

export const eyeIcon = (currentLanguage, show, setShow) => {
  return <InputAdornment
    position="end"
    onClick={() => setShow(!show)}
    className={currentLanguage ? currentLanguage.includes("en") ? "en_eyeicon" : "ar_eyeicon": "eyeIcon"}
  >
    {show ? <VisibilityIcon className='pointer' /> : <VisibilityOffIcon className='pointer' />}
  </InputAdornment>
}
