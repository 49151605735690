import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AddAdminUser from './view/Client/AdminPortal/AddEditAdminUser';
import AddMarket from './view/Client/AdminPortal/AddMarket';
import AddService from './view/Client/AdminPortal/AddService';
import AdminPortal from './view/Client/AdminPortal/AdminPortal';
import Login from './view/Client/Auth/Login';
import OtpVerify from './view/Client/Auth/OtpVerify';
import Register from './view/Client/Auth/Register';
import EditProfile from './view/Client/EditProfile/EditProfile';
import EmailConfirm from './view/Client/EmailConfirm';
import Home from './view/Client/Home/Home';
import NonLogin from './view/Client/Home/NonLogin/NonLogin';
import PricePlan from './view/Client/PricePlan/PricePlan';
import TopGainLose from './view/Client/TopGainLose/TopGainLose';
import ResetPass from './view/Client/Auth/ResetPass';
import ForgotPassLink from './view/Client/Auth/ForgotPassLink';
import AdminLogin from './view/Client/AdminPortal/AdminLogin';

function ProtectedRoute({ isAuthenticated, authenticationPath, outlet }) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}

function Routers({ currentLanguage }) {
  const loggedIn = localStorage.getItem('isLogin');
  const isConfirmed = localStorage.getItem("isConfirm");

  const defaultProtectedRouteProps = {
    isAuthenticated: !!loggedIn || !!isConfirmed,
    authenticationPath: '/',
  };

  return (
    <div className="route-pages">
      <Switch>
        <Route
          path="/home"
          component={() => (
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<Home />}
              currentLanguage={currentLanguage}
            />
          )}
        />
        <Route exact path="/price-plan" component={() => <PricePlan currentLanguage={currentLanguage} />} />
        <Route path="/live-data" component={() => <TopGainLose currentLanguage={currentLanguage} />} />
        <Route path="/" exact component={() => <NonLogin currentLanguage={currentLanguage} />} />
        <Route exact path="/admin" component={() => <AdminPortal currentLanguage={currentLanguage} />} />
        <Route exact path="/adminlogin" component={() => <AdminLogin currentLanguage={currentLanguage} />} />
        <Route
          exact
          path="/admin/add-edit-admin"
          component={() => <AddAdminUser />}
          currentLanguage={currentLanguage}
        />
        <Route
          exact
          path="/admin/add-edit-admin/:id"
          component={() => <AddAdminUser />}
          currentLanguage={currentLanguage}
        />
        <Route exact path="/add-market" component={() => <AddMarket />} currentLanguage={currentLanguage} />
        <Route exact path="/add-service" component={() => <AddService />} currentLanguage={currentLanguage} />

        <Route path="/login" component={() => <Login currentLanguage={currentLanguage} />} />
        <Route path="/register" component={() => <Register currentLanguage={currentLanguage} />} />
        <Route path="/resetpass" component={() => <ResetPass currentLanguage={currentLanguage} />} />
        <Route path="/forgotlink" component={() => <ForgotPassLink currentLanguage={currentLanguage} />} />
        <Route
          exact
          path="/confirm/:id"
          component={() => <EmailConfirm currentLanguage={currentLanguage} />}
        />
        <Route exact path="/verify" component={() => <OtpVerify currentLanguage={currentLanguage} />} />

        <Route exact path="/profile" component={() => <EditProfile currentLanguage={currentLanguage} />} />
        {/* <Redirect exact from="/" to="/home" /> */}
      </Switch>
    </div>
  );
}
export default Routers;
