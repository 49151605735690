import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { Checkbox, Grid, ListItemText } from '@mui/material';
import Marketchart from '../../../assets/images/Admin/Marketchart.svg';
import { Exchange } from '../../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import { AddUserMarket } from '../../../redux/action/AdminUser';
import CountrySelect from './CountrySelect';
import { useTranslation } from 'react-i18next';
import { AdminAddFooter } from '../../../components/Admin/AdminAddFooter';

const AddMarket = () => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const history = useHistory();
  const marketData = useSelector((state) => state.stock);
  const [exchangeName, setExchangeName] = useState([]);
  const [countryName, setCountryName] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setExchangeName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      exchange: exchangeName,
      country: countryName,
    };
    dispatch(AddUserMarket(data));
    if (marketData?.addMarketUser) {
      setTimeout(() => {
        setLoading(false);
        history.push('/admin');
      }, 3000)
    }
  };

  const handleCountryChange = (e) => {
    const { name, value } = e.target;
    setCountryName({ ...countryName, [name]: value });
  };

  return (
    <div className="add-market-main">
      <div>
        <Typography className="section-header" variant="h5">
          <img src={Marketchart} alt="Marketchart" />
          <span>{t("add_market")}</span>
        </Typography>
        <p>{t("add_user_dec")}</p>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Grid item container spacing={1} justify="center">
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                <label>{t("select_country")}</label>
                <FormControl fullWidth variant="outlined">
                  <CountrySelect country={countryName} handleChange={handleCountryChange} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                <label>{t("Exchange")}</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Occupation"
                    placeholder="Select Exchange"
                    className="country-select"
                    multiple
                    onChange={handleChange}
                    value={exchangeName}
                    renderValue={(selected) => selected.join(', ')}
                    name="exchange"
                  >
                    <MenuItem disabled value="">
                      Select Exchange
                    </MenuItem>
                    {Exchange.map((item) => (
                      <MenuItem key={item} value={item}>
                        <ListItemText primary={item} />
                        <Checkbox checked={exchangeName.indexOf(item) > -1} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          {AdminAddFooter(loading)}
        </form>
      </div>
    </div>
  );
};
export default AddMarket;
