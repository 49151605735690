import DataTable from '../Table/DataTable';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

export function Nyse({ param }) {
  const stockData = useSelector((state) => state.stock);
  return (
    <Grid>
      <div>
        <div style={{ overflowX: 'scroll' }}>
          <DataTable
            rows={stockData[param]?.nyse.rows.length > 0 ? stockData[param]?.nyse.rows : []}
            columns={stockData[param]?.nyse.columns.length > 0 ? stockData[param]?.nyse.columns : []}
          />
        </div>
      </div>
    </Grid>
  );
}
