import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Saudi from '../../assets/images/saudi.svg';
import ArrowAngle from '../../assets/images/arrowAngle.svg';
import './Subscribe.scss';
import { useTranslation } from 'react-i18next';
import { getGraphqlApi } from '../../services/apiCall.service';

function Subscribe({ currentLanguage }) {
  const { t } = useTranslation('home');
  const [marketName, setMarketName] = React.useState('');
  const [bageName, setBageName] = React.useState('');
  const [scoreValue, setScoreValue] = React.useState();
  const names = ['Saudi Market', 'India Market', 'USA Market'];
  const [marketNames] = React.useState(names)


  React.useEffect(() => {
    if (marketName === '') {
      setMarketName(marketNames[0]);
    }
  }, [marketName, marketNames]);
  React.useEffect(() => {
    let date =
      new Date().getFullYear() +
      '-' +
      ('0' + new Date().getMonth()).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2);
    getGraphqlApi(`index/fearandgreed/graphdata/${date}`, '')
      .then((res) => {
        if (res && res.fear_and_greed && res.fear_and_greed.score) {
          let score = res.fear_and_greed.score.toFixed();
          setScoreValue(score);
          if (score < 40) {
            setBageName('Buy');
          } else if (score < 20) {
            setBageName('Early Buy');
          } else if (score > 40) {
            setBageName('Early Sell');
          } else if (score > 60) {
            setBageName('Sell');
          }
        }
      })
      .catch((e) => {
        return e;
      });
  }, []);
  const handleChange = (event) => {
    setMarketName(event.target.value);
  };

  //   Between 40 - 21 Continue holding >>> Green
  //   50 - 41  Be ready for Sell off >>> Light Green
  // Between 60 - 41 Early sell >>> Light  Red
  // Between 70 - 61 Sell off >>> Red
  // Above 71 Sell off >> Dark Red

  return (
    <div className="subscribe-part">
      <div
        className="select-part"
        style={
          currentLanguage === 'ar'
            ? {
              direction: 'rtl',
            }
            : {
              direction: 'ltr',
            }
        }
      >
        <span className="s-market">{t('subscribedMarkets')}</span>
        <FormControl className="sel-part" variant="filled" sx={{ minWidth: 100 }}>
          {/* <InputLabel id="demo-simple-select-filled-label">Subscribed Markets</InputLabel> */}
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            className="menuItem"
            value={marketName}
            onChange={handleChange}
            style={{ display: 'grid' }}
          >
            {marketNames?.map((name, i) => (
              <MenuItem key={name} value={name}>
                <div className="value-part" style={{ display: 'flex' }}>
                  <img src={Saudi} width="25" alt='saudi' />
                  <span className="inner-part">{t(name)}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        className="status-part"
        style={
          currentLanguage === 'ar'
            ? {
              direction: 'rtl',
            }
            : {
              direction: 'ltr',
            }
        }
      >
        <div className="market-img">
          <img src={ArrowAngle} alt='arrow' />
        </div>
        <div className="market-status">
          <span className="m-label">{t('MarketStatus')}</span>
          <div className="score-main">
            {scoreValue >= 21 && scoreValue <= 40 && <span className="bage-green"> {t(bageName)} </span>}
            {scoreValue >= 41 && scoreValue <= 50 && <span className="bage"> {t(bageName)} </span>}
            {scoreValue >= 51 && scoreValue <= 60 && <span className="bage-light-red"> {t(bageName)} </span>}
            {scoreValue >= 61 && scoreValue <= 70 && <span className="bage-red"> {t(bageName)} </span>}
            {scoreValue >= 71 && <span className="bage-dark-red"> {t(bageName)} </span>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Subscribe;
