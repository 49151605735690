import {
    ADMINDATA,
    ADD_ADMIN_USER,
    ADD_MARKET_USER,
    MARKETDATA,
    CREATE_MARKET_INVESTOR,
    GET_MARKET_INVESTOR,
    LOGIN_ADMIN,
    UPDATE_ADMIN_USER,
    DELETE_ADMIN_USER,
    ADMIN_LOGIN_CONFIRM,
    CREATE_SUB_SERVICE,
    GET_SUB_SERVICE,
    UPDATE_ADMIN_PROFILE,
} from '../type';

let state_data = {};

const AdminUserReducer = (state = state_data, action) => {
    switch (action.type) {
        case ADMINDATA:
            let adminData = action.payload;
            return { ...state, adminData };
        case LOGIN_ADMIN:
            let adminUser = action.payload;
            return { ...state, adminUser };
        case UPDATE_ADMIN_PROFILE:
            let adminProfile = action.payload;
            return { ...state, adminProfile };
        case ADD_ADMIN_USER:
            let addAdminUser = action.payload;
            return { ...state, addAdminUser };
        case ADD_MARKET_USER:
            let addMarketUser = action.payload;
            return { ...state, addMarketUser };
        case MARKETDATA:
            let marketData = action.payload;
            return { ...state, marketData };
        case CREATE_MARKET_INVESTOR:
            let addMarketInvestor = action.payload;
            return { ...state, addMarketInvestor };
        case GET_MARKET_INVESTOR:
            let getMarketInvestor = action.payload;
            return { ...state, getMarketInvestor };
        case UPDATE_ADMIN_USER:
            let updateAdminUser = action.payload;
            return { ...state, updateAdminUser };
        case DELETE_ADMIN_USER:
            let deleteAdminUser = action.payload;
            return { ...state, deleteAdminUser };
        case ADMIN_LOGIN_CONFIRM:
            let AdminLoginUser = action.payload;
            return { ...state, AdminLoginUser };
        case CREATE_SUB_SERVICE:
            let addSubService = action.payload;
            return { ...state, addSubService };
        case GET_SUB_SERVICE:
            let getSubService = action.payload;
            return { ...state, getSubService };
        default:
            return state;
    }
};

export default AdminUserReducer;
