import * as React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';

export function AdminAddFooter(props) {
    const { t } = useTranslation('admin');
    return (
        <Grid item container spacing={1} style={{ position: 'relative', bottom: '-17em' }}>
        <Grid item sx={12} xs={6} sm={6} md={6}>
          <Button variant="outlined" href="/admin" className="cancel-btn">
            {t("cancel")}
          </Button>
        </Grid>
        <Grid item sx={12} xs={6} sm={6} md={6}>
          <Button variant="outlined" type='submit' className="save-btn">
            {props.loading ? <Loader /> : t("save")}
          </Button>
        </Grid>
      </Grid>
    );
}
