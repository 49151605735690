import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Logo from '../../assets/images/logo.svg';
import Men from '../../assets/images/men.svg';
import defualtUser from '../../assets/images/defualtUser.svg';
import './Header.scss';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ open, handleDrawerOpen, currentLanguage }) {
  const history = useHistory();
  const loggedIn = localStorage.getItem('isLogin');
  const userData = JSON.parse(localStorage.getItem("userData"));
  const adminData = JSON.parse(localStorage.getItem("adminUser"));

  const profileImage = () => {
    if (adminData) {
      return adminData?.profileImage
    } else if (userData) {
      return userData?.profileImage
    } else {
      return Men
    }
  }
  
  return (
    <AppBar position="fixed" color="default" className="MainHeader" open={open}>
      <Toolbar
        className="toolBar"
        style={
          currentLanguage === 'ar'
            ? {
              direction: 'rtl',
            }
            : {
              direction: 'ltr',
            }
        }
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          <img src={Logo} alt="logo" />
        </Typography>
        <Typography variant="h6" noWrap component="div" sx={{ cursor: 'pointer' }}>
          {(!loggedIn) ? (
            <img onClick={() => history.push('/login')} src={defualtUser} alt="defualtUser" />
          ) : (
            <img className='header-profile' src={profileImage()} alt="men" onClick={() => history.push('/profile')} />
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
