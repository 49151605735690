import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Collapsiblebtn from '../../assets/images/collapsiblePlusbtn.svg';
import CollapsibleMinusbtn from '../../assets/images/collapsibleMinusbtn.svg';
import { SubHistory } from '../../redux/action/investorPicks';
import { useDispatch, useSelector } from 'react-redux';

function Row(props) {
  const { row } = props;
  const stockData = useSelector((state) => state.stock);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (open) {
      dispatch(SubHistory());
    }
  }, [open]);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <div className="plus-icon">
                <img className="collepsible-icon" src={CollapsibleMinusbtn} alt="plus icon" />
                <p>{row.ticker}</p>
              </div>
            ) : (
              <div className="plus-icon">
                <img className="collepsible-icon" src={Collapsiblebtn} alt="plus icon" />
                <p>{row.ticker}</p>
              </div>
            )}
          </IconButton>
        </TableCell>
        {/* <TableCell>{row.ticker}</TableCell> */}
        <TableCell style={{ color: '#2596BE' }}>{row.name}</TableCell>
        <TableCell>{row.totalPercetageYeild}</TableCell>
        <TableCell>{row.noOfOccurrencies}</TableCell>
        <TableCell>{row.success}</TableCell>
        <TableCell>{row.numberOfSuccessTrades}</TableCell>
        <TableCell>{row.numberOfFailedTrade}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="collapsed-box">
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {stockData &&
                      stockData.subHistory.columns.length > 0 &&
                      stockData.subHistory.columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {column.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockData &&
                    stockData.subHistory.rows.length > 0 &&
                    stockData.subHistory.rows.map((historyRow) => (
                      <TableRow key={historyRow.no}>
                        <TableCell component="th" scope="row">
                          {historyRow.no}
                        </TableCell>
                        <TableCell>{historyRow.percent}</TableCell>
                        <TableCell align="right">{historyRow.change}</TableCell>
                        <TableCell align="right">{historyRow.target1}</TableCell>
                        <TableCell>{historyRow.target2}</TableCell>
                        <TableCell>{historyRow.fstCostAvg}</TableCell>
                        <TableCell>{historyRow.sndCost}</TableCell>
                        <TableCell>{historyRow.volume}</TableCell>
                        <TableCell>{historyRow.open}</TableCell>
                        <TableCell>{historyRow.close}</TableCell>
                        <TableCell>{historyRow.high}</TableCell>
                        <TableCell>{historyRow.low}</TableCell>
                        <TableCell>{historyRow.dateApp}</TableCell>
                        <TableCell>{historyRow.waiting}</TableCell>
                        <TableCell>{historyRow.news}</TableCell>
                        <TableCell>{historyRow.chart}</TableCell>
                        <TableCell>{historyRow.target1achieved}</TableCell>
                        <TableCell>{historyRow.target2achieved}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleDataTable(props) {
  const { rows, columns } = props;
  return (
    <TableContainer component={Paper} className="collapsible-table">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} columns={columns} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
