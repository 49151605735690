import {
  TOPGAIN,
  TOPLOSS,
  TRADERPICKS,
  INVESTORPICKS,
  HISTORY,
  SUBHISTORY,
} from '../type';
import { getApi, postApi } from '../../services/apiCall.service';

function GetInventoryPicks() {
  return (dispatch) => {
    getApi(`investor-picks`, '')
      .then((res) => {
        if (res.success) {
          dispatch({ type: INVESTORPICKS, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

function GetHistory() {
  return (dispatch) => {
    getApi(`history`, '')
      .then((res) => {
        if (res.success) {
          dispatch({ type: HISTORY, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

function SubHistory() {
  return (dispatch) => {
    getApi(`sub-history`, '')
      .then((res) => {
        if (res.success) {
          dispatch({ type: SUBHISTORY, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

function traderPicks() {
  return (dispatch) => {
    getApi(`trader-picks`, '')
      .then((res) => {
        if (res.success) {
          dispatch({ type: TRADERPICKS, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

function TopGain(data) {
  return (dispatch) => {
    postApi(`top-gain`, data)
      .then((res) => {
        if (res.success) {
          dispatch({ type: TOPGAIN, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

function TopLoss(data) {
  return (dispatch) => {
    postApi(`top-loss`, data)
      .then((res) => {
        if (res.success) {
          dispatch({ type: TOPLOSS, payload: res.data });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}


export {
  GetInventoryPicks,
  GetHistory,
  SubHistory,
  traderPicks,
  TopGain,
  TopLoss,
};
