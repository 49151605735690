import {
    ADD_USER,
    FORGOT_LINK,
    FORGOT_TOKEN_CHECK,
    LOGIN_USER,
    RESET_OTP,
    RESET_PASS,
    UPDATE_PROFILE,
    USER_CONFIRM,
    VERIFY_OTP,
} from '../type';

let state_data = {};

const authReducer = (state = state_data, action) => {
    switch (action.type) {
        case ADD_USER:
            let createUser = action.payload;
            return { ...state, createUser, status: 'Active' };
        case LOGIN_USER:
            let loginData = action.payload;
            return { ...state, loginData, login: true };
        case USER_CONFIRM:
            let confirm = action.payload;
            return { ...state, confirm };
        case FORGOT_LINK:
            let forgot = action.payload;
            return { ...state, forgot };
        case RESET_PASS:
            let resetPass = action.payload;
            return { ...state, resetPass };
        case UPDATE_PROFILE:
            let updateProfile = action.payload;
            return { ...state, updateProfile };
        case VERIFY_OTP:
            let verify = action.payload;
            return { ...state, verify, verifyUser: true };
        case RESET_OTP:
            let resetOtp = action.payload;
            return { ...state, resetOtp };
        case FORGOT_TOKEN_CHECK:
            let forgotcode = action.payload;
            return { ...state, forgotcode };
        default:
            return state;
    }
};

export default authReducer;
