import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../../assets/images/logo.svg';
import Home from '../../assets/images/home.svg';
import Price from '../../assets/images/price.svg';
import Service from '../../assets/images/service.svg';
import ScreenShort from '../../assets/images/screenshort.svg';
import About from '../../assets/images/about.svg';
import Contect from '../../assets/images/contect.svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import './SlideBar.scss';
const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

export function SlideBar({ open, handleDrawerClose, localList, handleLanguageChange, currentLanguage }) {
  const { t } = useTranslation('common');
  const history = useHistory();
  const wrapperRef = useRef();
  const loginUser = useSelector((state) => state.stock);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleDrawerClose();
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  }
  const token = JSON.parse(localStorage.getItem('userData'));
  const isLogin = localStorage.getItem("isLogin");

  const handleRoute = (path) => {
    if (token?.accessToken || isLogin || path === '/login' || path === '/register') {
      history.push(path);
    }
    if (!token?.accessToken && path === '/price-plan' || isLogin) {
      history.push(path);
    }
    handleDrawerClose();
  };

  useEffect(() => { }, [token, loginUser]);

  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('isLogin');
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("adminUser");
    localStorage.removeItem("verifyUser");
    localStorage.removeItem("updateProfile");
    history.push('/');
    handleDrawerClose();
  };

  return (
    <Drawer
      anchor={currentLanguage === 'ar' ? 'right' : 'left'}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      style={
        currentLanguage === 'ar'
          ? {
            direction: 'rtl',
          }
          : {
            direction: 'ltr',
          }
      }
      variant="persistent"
      open={open}
      ref={wrapperRef}
    >
      <DrawerHeader>
        {open && <img src={Logo} alt="logo" />}
        <IconButton onClick={handleDrawerClose}>
          {currentLanguage !== 'ar' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key="Home" disablePadding button component="button" onClick={() => handleRoute('/home')}>
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={Home} alt="Home" />
            </ListItemIcon>
            <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.home')} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="Plans & Prices"
          disablePadding
          button
          onClick={() => handleRoute('/price-plan')}
          component="button"
        >
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={Price} alt="Price" />
            </ListItemIcon>
            <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.plans')} />
          </ListItemButton>
        </ListItem>
        <ListItem key="Services" disablePadding button component="button">
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={Service} alt="Service" />
            </ListItemIcon>
            <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.services')} />
          </ListItemButton>
        </ListItem>
        <ListItem key="Screenshorts" disablePadding button component="button">
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={ScreenShort} alt="ScreenShort" />
            </ListItemIcon>
            <ListItemText
              className={`${currentLanguage === 'ar' ? 'rtl' : ''}`}
              primary={t('nav.screenshorts')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="About" disablePadding button component="button">
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={About} alt="About" />
            </ListItemIcon>
            <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.about')} />
          </ListItemButton>
        </ListItem>
        <ListItem key="Contact" disablePadding button component="button">
          <ListItemButton>
            <ListItemIcon className="sidebar-icon">
              <img src={Contect} alt="Contect" />
            </ListItemIcon>
            <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.contact')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <div className="select-part">
        <span className="langchange">{t('change')} </span>
        <FormControl className="sel-part" variant="filled" sx={{ minWidth: 100 }}>
          {/* <InputLabel id="demo-simple-select-filled-label">Subscribed Markets</InputLabel> */}
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            className="menuItem"
            value={currentLanguage}
            onChange={(e) => handleLanguageChange(e)}
            style={{ display: 'grid', minWidth: '240px', left: '0px' }}
          >
            {localList?.map((item) => (
              <MenuItem key={item.id} value={item.locale}>
                <div className="value-part" style={{ display: 'flex' }}>
                  <img
                    src={item.image}
                    alt={item.locale}
                    style={{ height: '24px', width: '24px', marginRight: '10px' }}
                  />
                  <span className="inner-part">{item.locale.toUpperCase()}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {(!isLogin) && (
        <div style={{ padding: '20px' }}>
          <ListItem
            key="Sign Up"
            disablePadding
            button
            component="button"
            onClick={() => handleRoute('/register')}
          >
            <ListItemButton>
              <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.signup')} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Sign In"
            disablePadding
            button
            component="button"
            onClick={() => handleRoute('/login')}
          >
            <ListItemButton>
              <ListItemText className={`${currentLanguage === 'ar' ? 'rtl' : ''}`} primary={t('nav.signin')} />
            </ListItemButton>
          </ListItem>
        </div>
      )}
      {(isLogin) && (
        <div style={{ padding: '20px' }}>
          <ListItem key="Sign Up" disablePadding button component="button" onClick={() => handleLogout()}>
            <ListItemButton>
              <ListItemText
                className={`${currentLanguage === 'ar' ? 'rtl' : ''}`}
                primary={t('nav.logout')}
              />
            </ListItemButton>
          </ListItem>
        </div>
      )}
    </Drawer>
  );
}
