import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jwt from "jwt-decode";
import { Box, Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordUser } from '../../../redux/action/Auth';
import { toast, ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { eyeIcon, handleChangePassword } from '../../../components/common';
import PasswordValidation from './PasswordValidation';
import Loader from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../components/Layout/AuthHeader';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
    injectStyle();
}

const initialValye = {
    password: '',
    passwordConfirm: ""
}

const ResetPass = (props) => {
    const { t } = useTranslation('auth');
    const history = useHistory();
    const dispatch = useDispatch();
    const createUser = useSelector((state) => state.authData);
    const [userId, setUserId] = useState();
    const [showPassword, setShowPassword] = useState();
    const [linkExpTime, setLinkExpTime] = useState();
    const [showConfiPassword, setShowConfiPassword] = useState();
    const [expanded, setExpanded] = useState('panel1');
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t("pass_required")).matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, One Special Case Character"
        ),
        passwordConfirm: Yup.string().when('password', {
            is: undefined,
            then: Yup.string(),
            otherwise: Yup
                .string()
                .required(t("confirm_pass_err"))
                .oneOf([Yup.ref('password'), null], t("password_not_match")),
        }),
    })

    useEffect(() => {
        const userIdToken = history?.location?.pathname?.split("/")[2];
        const decodeId = jwt(userIdToken);
        setUserId(decodeId.userId)

        const linkExpire = history?.location?.search?.split("=")?.[1];
        const timeStampDecode = jwt(linkExpire);
        setLinkExpTime(timeStampDecode);
    }, [userId])

    const handleChangeAcco = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (createUser?.resetPass?.[0]?.status === "Active") {
            if (createUser?.resetPass?.[0]?.isConfirm) {
                setTimeout(() => {
                    setLoading(false)
                    history.push("/login");
                }, 3000)
            }
        }
    }, [createUser])

    const expire = moment(linkExpTime);
    const today = moment(new Date());
    const timeDiff = today.diff(expire, "days");

    return (
        <div style={
            props.currentLanguage === 'ar'
                ? {
                    direction: 'rtl',
                }
                : {
                    direction: 'ltr',
                }
        }>
            <AuthHeader title={t("reset_pass")} description={t("reset_description")} signin={t("signin")} signup={t("signup")} />
            <Box>
                <Formik
                    initialValues={initialValye}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        const data = {
                            password: values.password,
                            id: userId
                        }
                        if (timeDiff > 7) {
                            toast('Your password reset link has expired. please regenerate.');
                        } else {
                            dispatch(resetPasswordUser(data));
                            setLoading(true);
                            setTimeout(() => {
                                setLoading(false);
                            }, 3000)
                        }
                    }}
                >
                    {({ errors, touched, values, handleChange }) => {
                        return (
                            <>
                                <Form>
                                    <div className='login-form form-group'>
                                        <Grid item container spacing={1} style={{ justifyContent: "space-between" }}>
                                            <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                                                <div style={{ display: "flex", justifyContent: "space-between", position: 'relative' }}>
                                                    <Field
                                                        name="password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={values.password}
                                                        placeholder={t("enter_pass")}
                                                        className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                        style={{ marginTop: "0px" }}
                                                        onChange={handleChangePassword(handleChange)}
                                                        id="psw"
                                                        lang={props.currentLanguage}
                                                    />
                                                    {eyeIcon(props.currentLanguage, showPassword, setShowPassword)}
                                                </div>
                                                {errors.password && touched.password ? (
                                                    <PasswordValidation currentLanguage={props.currentLanguage} expanded={expanded} handleChangeAcco={handleChangeAcco} />
                                                ) : null}
                                            </Grid>
                                            <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                                                <div style={{ display: "flex", justifyContent: "space-between", position: 'relative' }}>
                                                    <Field
                                                        name="passwordConfirm"
                                                        type={showConfiPassword ? 'text' : 'password'}
                                                        value={values.passwordConfirm}
                                                        placeholder={t("enter_confirm_pass")}
                                                        className={`form-control ${touched.passwordConfirm && errors.passwordConfirm ? "is-invalid" : ""}`}
                                                        style={{ marginTop: "0px" }}
                                                    />
                                                    {eyeIcon(props.currentLanguage, showConfiPassword, setShowConfiPassword)}
                                                </div>
                                                {errors.passwordConfirm && touched.passwordConfirm ? (
                                                    <div className='error-msg'>{errors.passwordConfirm}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Button className="login-btn" type='submit' fullWidth>
                                            {loading ? <Loader /> : t("reset_pass")}
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </Box>
            <ToastContainer />
        </div>
    )
}
export default ResetPass;