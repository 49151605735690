export const API_BASE_URL = process.env.REACT_APP_LIVE_URL;

export const LANGUAGES = [
  'en',
  'fr',
  'hi',
  'bn',
  'ur',
  'ru',
  'zh',
  'es',
  'por ',
  'te',
  'tr',
  'ja',
  'jv',
  'ko',
  'pan',
];

export const AUTHORIZATION = {
  TOKEN: 'token',
};

export const DIRECTION = {
  LTR: 'ltr',
  RTL: 'rtl',
};

export const GRPHQL_BASE_URL = process.env.REACT_APP_THIRD_PARTY_URL;

export const LABEL_USERNAME = 'Email or Mobile Number';

export const LABEL_PASSWORD = 'Password';

export const LABEL_BUTTON_SIGN_IN = 'Sign In';

export const LABEL_BUTTON_SIGN_UP = 'Sign up';

export const LABEL_HEADER_LOGIN_OPTIONS = 'Or Login With';

export const LABEL_SWITCH_PROVIDER_LOGIN = 'Sign up as provider';