import React from 'react';
import './Loader.scss';

class Loader extends React.Component {
  render() {
    return (
      <div className="loading"></div>
    );
  }
}
export default Loader;
