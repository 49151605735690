import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { forgotUser } from '../../../redux/action/Auth';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../components/Layout/AuthHeader';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
    injectStyle();
}

const initialValue = {
    email: '',
}

const ForgotPassLink = (props) => {
    const { t } = useTranslation('auth');
    const language = localStorage.getItem("i18nextLng") ?? "en"
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t("invalid_email")).required(t("email_required")),
    })

    return (
        <div style={
            props.currentLanguage === 'ar'
                ? {
                    direction: 'rtl',
                }
                : {
                    direction: 'ltr',
                }
        }>
            <AuthHeader title={t("forgot_pass_link")} description={t("forgot_description")} />
            <Box>
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        await dispatch(forgotUser({ ...values, language: language }));
                    }}
                >
                    {({ errors, touched, values }) => {
                        return (
                            <>
                                <Form>
                                    <div className='login-form form-group'>
                                        <Grid item container spacing={1} style={{ justifyContent: "space-between" }}>
                                            <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Field
                                                        name="email"
                                                        type="text"
                                                        value={values.email}
                                                        placeholder={t("enter_email")}
                                                        className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                        style={{ marginTop: "0px" }}
                                                    />
                                                </div>
                                                {errors.email && touched.email ? (
                                                    <div className='error-msg'>{errors.email}</div>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Button className="login-btn" type='submit' fullWidth>
                                            {t("reset_pass_link")}
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </Box>
            <ToastContainer />
        </div>
    )
}
export default ForgotPassLink;