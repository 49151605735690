import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { FormControl, Grid, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { AddAdminUsers, GetAdmin, UpdateAdminUsers } from '../../../redux/action/AdminUser';
import { CustomInput } from '../../../components/CustomInput';
import './AdminPortal.scss';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const AddEditAdminUser = () => {
  const { t } = useTranslation('admin');
  const adminData = useSelector((state) => state.adminUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const [adminList, setAdminList] = useState();
  const userid = history.location?.pathname?.split("/")?.[3];
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("error.firstname")),
    lastName: Yup.string().required(t("error.lastname")),
    fullName: Yup.string().required(t("error.fullname")),
    userId: Yup.string().required(t("error.userid")),
    username: Yup.string().required(t("error.username")),
    email: Yup.string().email(t("error.invalid_email")).required(t("error.email")),
    mobile: Yup.string().required(t("error.mobile")),
  });

  const initData = () => {
    const index = _.findIndex(adminData?.adminData ?? [], { _id: userid });
    const adminUserInfo = adminData?.adminData ? adminData?.adminData[index] : {};
    setAdminList(adminUserInfo)
  }

  const initialValue = {
    firstName: adminList?.firstName ?? '',
    lastName: adminList?.lastName ?? '',
    fullName: adminList?.fullName ?? '',
    userId: adminList?.userId ?? '',
    username: adminList?.username ?? '',
    email: adminList?.email ?? '',
    mobile: adminList?.mobile ?? '',
    MFAEnable: adminList?.MFAEnable ?? 'No',
    role: 'user',
    userLogin: true,
    adminId: JSON.parse(localStorage.getItem("adminUser"))?._id
  };

  useEffect(() => {
    initData();
  }, [adminData?.adminData])

  useEffect(() => {
    dispatch(GetAdmin());
  }, [])

  const button = () => {
    if (userid)
      return t("update")
    else
      return t("save")
  }

  return (
    <div className="main-add-user">
      <div>
        <Typography className="section-header" variant="h5">
          {userid ? t("edit_user") : t("add_user")}
        </Typography>
        <p> {userid ? t("edit_user_dec") : t("add_user_dec")}</p>
      </div>
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (userid) {
              await dispatch(UpdateAdminUsers({ ...values, id: userid }))
            } else {
              await dispatch(AddAdminUsers(values));
            }
            setTimeout(() => {
              setLoading(false);
              history.push('/admin');
            }, 3000)
          }}
        >
          {({ errors, touched, values }) => {
            return (
              <>
                <Form>
                  <div className="form-group">
                    <Grid item container spacing={1} justify="center">
                      <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                        <CustomInput
                          label={t("First Name")}
                          name="firstName"
                          type="text"
                          value={values.firstName}
                          touched={touched.firstName}
                          errors={errors.firstName}
                        />
                      </Grid>
                      <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                        <CustomInput
                          label={t("Last Name")}
                          name="lastName"
                          type="text"
                          value={values.lastName}
                          touched={touched.lastName}
                          errors={errors.lastName}
                        />
                      </Grid>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <CustomInput
                          label={t("Full Name")}
                          name="fullName"
                          type="text"
                          value={values.fullName}
                          touched={touched.fullName}
                          errors={errors.fullName}
                        />
                      </Grid>

                      <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                        <CustomInput
                          label={t("User Id")}
                          name="userId"
                          type="text"
                          value={values.userId}
                          touched={touched.userId}
                          errors={errors.userId}
                        />
                      </Grid>
                      <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                        <CustomInput
                          label={t("user_name")}
                          name="username"
                          type="text"
                          value={values.username}
                          touched={touched.username}
                          errors={errors.username}
                        />
                      </Grid>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <CustomInput
                          label={t("email")}
                          name="email"
                          type="text"
                          value={values.email}
                          touched={touched.email}
                          errors={errors.email}
                        />
                      </Grid>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <CustomInput
                          label={t("mobile")}
                          name="mobile"
                          type="text"
                          value={values.mobile}
                          touched={touched.mobile}
                          errors={errors.mobile}
                        />
                      </Grid>
                    </Grid>
                    <FormControl className="input-field">
                      <div id="my-radio-group">{t("mfa_enable")}</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="MFAEnable" value="Yes" />
                          {t("yes")}
                        </label>
                        <label>
                          <Field type="radio" name="MFAEnable" value="No" />
                          {t("no")}
                        </label>
                      </div>
                    </FormControl>
                  </div>
                  <Grid item container spacing={1} style={{ position: 'relative', bottom: '-40px' }}>
                    <Grid item sx={12} xs={6} sm={6} md={6}>
                      <Button variant="outlined" href="/admin" className="cancel-btn">
                        {t("cancel")}
                      </Button>
                    </Grid>
                    <Grid item sx={12} xs={6} sm={6} md={6}>
                      <Button variant="outlined" type="submit" className="save-btn">
                        {loading ? <Loader /> : button()}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
}
export default AddEditAdminUser;
