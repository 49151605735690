import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const PasswordValidation = (props) => {
  const { t } = useTranslation('auth');
  const { expanded, handleChangeAcco } = props
  return (
    <>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChangeAcco('panel1')}
        className="accordian"
        style={{
          background: '#f1f1f1',
          boxShadow: 'none',
          borderRadius: '5px',
          marginTop: !expanded ? '15px' : '',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component="h4">{t("error_title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul className="password-error">
              <div className="error-msg">
                <p id="length" class={props.currentLanguage === "en" ? "invalid" : "ar_invalid"}>
                  {t("pass_error_1")}
                </p>
                <p id="capital" class={props.currentLanguage === "en" ? "invalid" : "ar_invalid"}>
                  {t("pass_error_2")}
                </p>
                <p id="letter" class={props.currentLanguage === "en" ? "invalid" : "ar_invalid"}>
                  {t("pass_error_3")}
                </p>
                <p id="number" class={props.currentLanguage === "en" ? "invalid" : "ar_invalid"}>
                  {t("pass_error_4")}
                </p>
                <p id="special" class={props.currentLanguage === "en" ? "invalid" : "ar_invalid"}>
                  {t("pass_error_5")}
                </p>
              </div>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default PasswordValidation 