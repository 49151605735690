import { Box, Tab } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { marketInvestorColumn } from '../../../components/common';
import DataTable from '../../../components/Table/DataTable';
import AddInvestors from './AddInvestors';

const AdminMarketTab = ({ currentLanguage, marketRecommTab, handleChangeMarketTab }) => {
    const { t } = useTranslation('admin');
    const adminData = useSelector((state) => state.adminUser);
    const [openAddMaket, setOpenAddMarket] = useState(false);
    return <>
        <Box style={{ overflowX: 'scroll' }}>
            <TabContext value={marketRecommTab}>
                <Box style={currentLanguage === 'ar'
                    ? {
                        direction: 'rtl',
                    }
                    : {
                        direction: 'ltr',
                    }}>
                    <TabList
                        onChange={handleChangeMarketTab}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        <Tab className="tab-view" label={t("investor")} value="investor" />
                        <Tab className="tab-view" label={t("traders")} value="trader" />
                        <Tab className="tab-view" label={t("marketStatus")} value="marketStatus" />
                    </TabList>
                </Box>
                <TabPanel value="investor" style={{ padding: '24px 0px' }}>
                    {!openAddMaket && (
                        <>
                            {' '}
                            <div className="add-new-admin" onClick={() => setOpenAddMarket(true)}>
                                <p>+ {t("add_new")}</p>
                            </div>
                        </>
                    )}
                    <div style={{ overflowX: 'scroll', marginTop: '30px' }}>
                        {!openAddMaket ? (
                            <DataTable
                                rows={
                                    adminData?.getMarketInvestor !== undefined &&
                                        adminData?.getMarketInvestor?.length > 0
                                        ? adminData?.getMarketInvestor
                                        : []
                                }
                                columns={marketInvestorColumn?.length > 0 ? marketInvestorColumn : []}
                            />
                        ) : (
                            <AddInvestors setOpenAddMarket={setOpenAddMarket} />
                        )}
                    </div>
                </TabPanel>
                <TabPanel value="trader" style={{ padding: '24px 0px' }}>
                    Traders
                </TabPanel>
                <TabPanel value="marketStatus" style={{ padding: '24px 0px' }}>
                    <div style={{ marginBottom: '30px' }} className={`userSubTabsbtn `}>
                        market status
                    </div>
                </TabPanel>
            </TabContext>
        </Box></>
}
export default AdminMarketTab;