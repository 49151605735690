import React, { useEffect, useState } from 'react';
import './TopGainLose.scss';
import { Search } from '../../../components/Search/Search';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import USA from '../../../assets/images/usa.svg';
import UK from '../../../assets/images/uk.svg';
import ASIA from '../../../assets/images/asia.svg';
import EMEA from '../../../assets/images/emea.svg';
import { Nasdaq } from '../../../components/Nasdaq/NASDAQ';
import { Nyse } from '../../../components/Nyse/NYSE';
import Box from '@mui/material/Box';
import { TopGain, TopLoss } from '../../../redux/action/investorPicks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function TopGainLose({ currentLanguage }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('liveData');
  const [expanded, setExpanded] = React.useState('USA');
  const [lossExpanded, setLossExpanded] = React.useState('USA');
  const [lossManageTable, setLossManageTable] = useState(false);
  const [manageTable, setManageTable] = useState(false);

  useEffect(() => {
    if (expanded) {
      dispatch(TopGain({ country: expanded }));
    }
  }, [expanded]);

  useEffect(() => {
    if (lossExpanded) {
      dispatch(TopLoss({ country: lossExpanded }));
    }
  }, [lossExpanded]);

  const handleChange = (panel) => (event, newExpanded) => {
    setManageTable(false);
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeLoss = (panel) => (event, newExpanded) => {
    setLossManageTable(false);
    setLossExpanded(newExpanded ? panel : false);
  };

  const accordionData = [
    {
      name: 'USA',
      image: USA,
    },
    {
      name: 'UK',
      image: UK,
    },
    {
      name: 'Asia',
      image: ASIA,
    },
    {
      name: 'EMEA',
      image: EMEA,
    },
  ];
  //prevCheck => !prevCheck
  return (
    <div className="top-gain-lose-main">
      <Search currentLanguage={currentLanguage} />
      <div className="top-main-part">
        <Box
          sx={{ my: 3 }}
          style={
            currentLanguage === 'ar'
              ? {
                  textAlign: 'right',
                }
              : {
                  textAlign: 'left',
                }
          }
        >
          <Typography className="gainer-header" variant="p">
            {t('topGain')}
          </Typography>
        </Box>
        {accordionData?.map((data, index) => {
          return (
            <Accordion
              style={currentLanguage === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}
              expanded={expanded === data.name}
              onChange={handleChange(data.name)}
              className="accordian"
              key={index}
            >
              <AccordionSummary
                aria-controls={`${data.name}d-content`}
                id={`${data.name}d-header`}
                className="accor-summary"
              >
                <Typography>
                  <img src={data.image} alt={data.name} /> {data.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accor-detail">
                <div className="btn-part">
                  <Button
                    className={`${!manageTable ? 'active' : 'deactive'}`}
                    variant="contained"
                    onClick={() => setManageTable(false)}
                  >
                    {t('buttons.nasdaq')}
                  </Button>
                  <Button
                    className={`${manageTable ? 'active' : 'deactive'}`}
                    variant="contained"
                    onClick={() => setManageTable(true)}
                  >
                    {t('buttons.nyse')}
                  </Button>
                </div>
                {manageTable ? <Nyse param="topGain" /> : <Nasdaq param="topGain" />}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Box
          sx={{ my: 3 }}
          style={
            currentLanguage === 'ar'
              ? {
                  textAlign: 'right',
                }
              : {
                  textAlign: 'left',
                }
          }
        >
          <Typography className="gainer-header" variant="p">
            {t('topLoss')}
          </Typography>
        </Box>
        {accordionData?.map((data, i) => {
          if (data.name === 'USA' || data.name === 'EMEA') {
            return (
              <Accordion
                style={currentLanguage === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}
                expanded={lossExpanded === data.name}
                onChange={handleChangeLoss(data.name)}
                className="accordian"
                key={i}
              >
                <AccordionSummary
                  aria-controls={`${data.name}d-content`}
                  id={`${data.name}d-header`}
                  className="accor-summary"
                >
                  <Typography>
                    <img src={data.image} alt={data.name} /> {data.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accor-detail">
                  <div className="btn-part">
                    <Button
                      className={`${!lossManageTable ? 'active' : 'deactive'}`}
                      variant="contained"
                      onClick={() => setManageTable(false)}
                    >
                      {t('buttons.nasdaq')}
                    </Button>
                    <Button
                      className={`${lossManageTable ? 'active' : 'deactive'}`}
                      variant="contained"
                      onClick={() => setManageTable(true)}
                    >
                      {t('buttons.nyse')}
                    </Button>
                  </div>
                  {lossManageTable ? <Nyse param="topLoss" /> : <Nasdaq param="topLoss" />}
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
      </div>
    </div>
  );
}
export default TopGainLose;
