import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import { Divider, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PersonPinIcon from '@mui/icons-material/PersonOutline';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import { useTranslation } from 'react-i18next';
import './PricePlan.scss';

function PricePlan({ currentLanguage }) {
  const [alignment, setAlignment] = useState('individual');
  const { t } = useTranslation('priceplan');
  const [value, setValue] = useState('monthly');

  const handleChangeTab = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangeTime = (event, newValue) => {
    setValue(newValue);
  };

  const subButton = () => {
    return <>
      <div className="subscribe">
        <button>{t('subscribe')}</button>
        <p>
          {t('trial')} <b></b>
        </p>
      </div>
      <div className="key-benifit">
        <h4>{t('keyBenifits')}</h4>
        <ul>
          <li>{t('topGained')}</li>
          <li>{t('history')}</li>
          <li>{t('maxNo')}</li>
          <li>{t('email_notification')}</li>
          <li>{t('SMSmobile')}</li>
          <li>{t('marketStatus')}</li>
        </ul>
      </div>
    </>
  }

  const yearMonth = () => {
    return <p>
      {t('USD')}
      <span>
        {value === 'yearly' ? (
          t('year')
        ) : (
          t('month')
        )}
      </span>
    </p>
  }

  const monthPackage = () => {
    return <>
      <div className="package-value">
        <h3>$ 0</h3>
        {yearMonth()}
      </div>
      {subButton()}
    </>
  }

  const yearPackage = () => {
    return <>
      <div className="package-value">
        <pre>$150</pre>
        <h3>$ 1</h3>
        {yearMonth()}
      </div>
      {subButton()}
    </>
  }

  const mainPackages = (free, standard) => {
    return <div className="main-package">
      <Grid container className="outer-grid">
        <Grid item sx={12} xs={5} md={5}>
          <div className="price-packages">
            <div className="package">
              <h3>{free}</h3>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            {monthPackage()}
          </div>
        </Grid>
        <Grid item sx={0} xs={1} md={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider orientation="vertical" variant="middle" flexItem />
        </Grid>
        <Grid item sx={12} xs={5} md={5}>
          <div className="price-packages">
            <div className="package">
              <h3>{standard}</h3>
              <p>Lorem Ipsum is simply dummy text of the printing.</p>
            </div>
            {yearPackage()}
          </div>
        </Grid>
      </Grid>
    </div>
  }

  return (
    <div className="price-main">
      <div className="price-title">
        <div className="price-start">
          <h4>{t('readyToGet')}</h4>
          <p>{t('choose')} </p>
        </div>
        <div style={{ marginBottom: '30px' }}>
          <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChangeTab}>
            <ToggleButton value="individual">
              <PersonPinIcon />
              {t('individual')}
            </ToggleButton>
            <ToggleButton value="commercial">
              <BusinessCenter />
              {t('commercial')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {alignment === 'individual' ? (
          <TabContext value={value}>
            <TabList
              onChange={handleChangeTime}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              className="individual-tabs"
              style={
                currentLanguage === 'ar'
                  ? {
                    direction: 'rtl',
                  }
                  : {
                    direction: 'ltr',
                  }
              }
            >
              <Tab label={t(`monthly`)} value="monthly" />
              <Tab label={t(`yearly`)} value="yearly" />
            </TabList>
            {mainPackages(t('free_individual'), t('standard_individual'))}
          </TabContext>
        ) : (
          <TabContext value={value}>
            <TabList
              onChange={handleChangeTime}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              className="individual-tabs"
              style={
                currentLanguage === 'ar'
                  ? {
                    direction: 'rtl',
                  }
                  : {
                    direction: 'ltr',
                  }
              }
            >
              <Tab label={`Monthly`} value="monthly" />
              <Tab label={`Yearly`} value="yearly" />
            </TabList>
            {mainPackages(t('free_commercial'), t('standard_commercial'))}
          </TabContext>
        )}
      </div>
    </div>
  );
}
export default PricePlan;
