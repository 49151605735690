import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Men from '../../../assets/images/men.svg';
import imageIcon from '../../../assets/images/defaultImge.svg';
import { Field, Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { UpdateProfileUser } from '../../../redux/action/Auth';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { CustomInput } from '../../../components/CustomInput';
import PasswordValidation from '../Auth/PasswordValidation';
import { base64ToArrayBuffer, eyeIcon, handleChangePassword } from '../../../components/common';
import { UpdateAdminProifle } from '../../../redux/action/AdminUser';
import './EditProfile.scss';


// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const EditProfile = (props) => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const adminData = JSON.parse(localStorage.getItem("adminUser"));
  const [changePass, setChangePass] = useState(false);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfiPassword, setShowConfiPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const validationSchema = Yup.object().shape({
    password: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, One Special Case Character"
    ),
    rePassword: Yup.string().when('password', {
      is: undefined,
      then: Yup.string(),
      otherwise: Yup
        .string()
        .oneOf([Yup.ref('password'), null], t("password_not_match")),
    }),
  });

  const initialValues = {
    username: adminData ? adminData?.username : userData?.username ?? '',
    password: '',
    profileImage: adminData ? adminData?.profileImage : userData?.profileImage,
    rePassword: ""
  };
  const handleFile = (e, fieldName) => {
    const { files, value } = e.target
    if (!files[0]) return;
    const file = !files?.length ? new Blob() : files[0];
    setFile(URL.createObjectURL(file))
    setFile((merchantData) => {
      return {
        [fieldName]: fieldName === 'profile' ? file : value,
        'profileImage': fieldName === 'profile' ? URL.createObjectURL(file) : merchantData['profileImage'],
      }
    });
  };

  const handleChangeAcco = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const profileImage = () => {
    if (adminData) {
      return adminData?.profileImage
    } else {
      return userData?.profileImage
    }
  }



  return (
    <div className="edit-profile" style={
      props.currentLanguage === 'ar'
        ? {
          direction: 'rtl',
        }
        : {
          direction: 'ltr',
        }
    }>
      <div className='edit-profile-pass'>
        <Formik initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const formData = new FormData();
            formData.append("username", values.username);
            formData.append("password", values.password)
            if (adminData) {
              formData.append("profileImage", file?.profile ? file?.profile : base64ToArrayBuffer(adminData?.profileImage));
              formData.append("id", adminData?._id || "");
              await dispatch(UpdateAdminProifle(formData));
            } else {
              formData.append("profileImage", file?.profile ? file?.profile : base64ToArrayBuffer(userData?.profileImage));
              formData.append("id", userData?.id || "");
              await dispatch(UpdateProfileUser(formData));
            }
            setTimeout(() => {
              setLoading(false);
              history.push("/home");
            }, 3000)

          }}>
          {({ errors, touched, values, handleChange }) => {
            return (
              <Form>
                <div>
                  <input type="file" className='file-upload' accept="image/png, image/jpg, image/jpeg" onChange={(e) => handleFile(e, "profile")} />
                  <div className="default-icon">
                    {file || userData?.profileImage || adminData?.profileImage ? <img src={file?.profileImage ? file?.profileImage : profileImage()} alt="men" className="profile-image" /> : <img src={Men} alt="men" className="profile-image" />}
                    <img src={imageIcon} alt="image_icon" className="image-icon" />
                  </div>
                </div>
                <div className="form-group">
                  <Grid item container spacing={1} justify="center">
                    <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                      <CustomInput
                        label={t("name")}
                        name="username"
                        type="text"
                        value={values.username}
                      />
                    </Grid>
                    <Grid item container spacing={1} style={{ position: 'relative', justifyContent: 'start', marginTop: "10px" }}>
                      <Grid item sx={12} xs={6} sm={6} md={6}>
                        <Button variant="outlined" type="button" onClick={() => setChangePass(!changePass)}>
                          {t("change_pass")}
                        </Button>
                      </Grid>
                    </Grid>
                    <div className={changePass ? 'change_pass' : 'pass-edit'}>
                      <div className="change-title">
                        <Typography variant="v4">{t("change_pass")}</Typography>
                      </div>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", position: 'relative' }}>
                          <label>{t("password")}</label>
                          <Field
                            label={t("password")}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChangePassword(handleChange)}
                            className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                            id="psw"
                            lang={props.currentLanguage}
                          />
                          {eyeIcon(props.currentLanguage, showPassword, setShowPassword)}
                        </div>
                        {errors.password && touched.password ? (
                          <PasswordValidation currentLanguage={props.currentLanguage} expanded={expanded} handleChangeAcco={handleChangeAcco} />
                        ) : null}
                      </Grid>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", position: 'relative' }}>
                          <CustomInput
                            label={t("re_password")}
                            name="rePassword"
                            type={showConfiPassword ? 'text' : 'password'}
                            value={values.rePassword}
                            touched={touched.rePassword}
                            errors={errors.rePassword}
                          />
                          {eyeIcon(props.currentLanguage, showConfiPassword, setShowConfiPassword)}
                        </div>
                      </Grid>
                    </div>
                    <Grid item container spacing={1} style={{ position: 'relative', bottom: '-5rem', justifyContent: 'center' }}>
                      <Grid item sx={12} xs={6} sm={6} md={6}>
                        <Button variant="outlined" type="submit" className="save-btn">
                          {loading ? <Loader /> : t("save_changes")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};
export default EditProfile;
