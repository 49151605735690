import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resendOtp, verifyOtp } from '../../../redux/action/Auth';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { ToastContainer } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../components/Layout/AuthHeader';

const initialValye = {
    emailOtp: '',
}

const OtpVerify = (props) => {
    const { t } = useTranslation('auth');
    const language = localStorage.getItem("i18nextLng") ?? "en"
    const history = useHistory();
    const dispatch = useDispatch();
    const verifyData = JSON.parse(localStorage.getItem('userData'));
    const verifyUser = useSelector((state) => state.authData);
    const initialMinute = 5, initialSeconds = 0;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        // username: Yup.string().required("Full name required!"),
        emailOtp: Yup.string().required("OTP required!")
    })

    // CALL IT ONCE IN YOUR APP
    if (typeof window !== "undefined") {
        injectStyle();
    }
    const timing = () => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }
    useEffect(() => {
        timing()
    }, [minutes, seconds]);

    useEffect(() => {
        if (verifyUser?.verifyUser) {
            setTimeout(() => {
                setLoading(false);
                history.push("/home")
            }, 3000)
        }
    }, [verifyUser])

    const resendCode = () => {
        timing()
        dispatch(resendOtp({ email: verifyData?.email, language: language }))
    }

    return (
        <div style={
            props.currentLanguage === 'ar'
                ? {
                    direction: 'rtl',
                }
                : {
                    direction: 'ltr',
                }
        }>
            <AuthHeader title={t("verify_acc")} description={t("verify_description")} />
            <Box>
                <Formik
                    initialValues={initialValye}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        dispatch(verifyOtp({ ...values, email: verifyData?.email }))
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 3000)
                    }}
                >
                    {({ errors, touched, values }) => {
                        return (
                            <>
                                <Form>
                                    <div className='login-form form-group'>
                                        <Grid item container spacing={1} style={{ justifyContent: "space-between" }}>
                                            <Grid item sx={7} xs={7} sm={7} md={7} className="input-field">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Field
                                                        name="emailOtp"
                                                        type="text"
                                                        value={values.emailOtp}
                                                        placeholder={t("enter_otp")}
                                                        className={`form-control ${touched.emailOtp && errors.emailOtp ? "is-invalid" : ""}`}
                                                        style={{ marginTop: "0px" }}
                                                    />
                                                </div>
                                                {errors.emailOtp && touched.emailOtp ? (
                                                    <div className='error-msg'>{errors.emailOtp}</div>
                                                ) : null}
                                            </Grid>
                                            <Grid item sx={4} xs={4} sm={4} md={4} className="input-field">
                                                <Button type='button' className="resend-btn" onClick={() => resendCode()}>
                                                    {t("resend_code")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <p style={{ textAlign: "end", color: "#384955" }}>{minutes === 0 && seconds === 0
                                            ? null
                                            : <p> {t("timer")}: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
                                        }</p>
                                        <Button className="login-btn" type='submit' fullWidth>
                                            {loading ? <Loader /> : t("verify")}
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </Box>
            <ToastContainer />
        </div>
    )
}
export default OtpVerify;