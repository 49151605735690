/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';
import './Home.scss';
import Subscribe from '../../../components/Subscribe/Subscribe';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Calender from '../../../assets/images/calender.svg';
import Clock from '../../../assets/images/clock.svg';
import Sync from '../../../assets/images/sync.svg';
import CollapsibleDataTable from '../../../components/Table/CollapsibleDataTable';
import { GetInventoryPicks, GetHistory, traderPicks } from '../../../redux/action/investorPicks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MultiheaderTable from '../../../components/Table/ MultiheaderTable';

function Home({ currentLanguage }) {
  const history = useHistory();
  const [value, setValue] = useState('1');
  const [tranderValue, setTranderValue] = useState('3');
  const { t } = useTranslation('home');
  const dispatch = useDispatch();
  const stockData = useSelector((state) => state.stock);
  const handleChange = (event, newValue) => {
    dispatch(GetHistory());
    setValue(newValue);
  };

  const handleChangeTrander = (event, newValue) => {
    dispatch(GetHistory());
    setTranderValue(newValue);
  };

  useEffect(() => {
    dispatch(GetInventoryPicks());
    dispatch(traderPicks());
  }, [dispatch]);

  const tableMultiPanel = () => {
    return <>
      <div className="investor-header">
        <div className="label-part">
          <span className="inv-label">{t('added')}</span>
          <span className="inv-label">{t('lastUpdate')}</span>
        </div>
        <div className="label-part">
          <span className="label-inner">
            <img src={Calender} alt="calander"></img>
            <span>08 July 2022</span>
            <img src={Clock} alt="clock"></img>
            <span>01:00 PM</span>
          </span>
          <span className="label-inner">
            <img src={Calender} alt="calander"></img>
            <span>08 July 2022</span>
            <img src={Clock} alt="clock"></img>
            <span>01:00 PM</span>
          </span>
        </div>
        <div className="label-part">
          <img src={Sync} alt="sync"></img>
        </div>
      </div>
      <Grid>
        <Grid>
          <Box>
            <div style={{ overflowX: 'scroll' }}>
              <MultiheaderTable
                rows={stockData.investorPicks.rows}
                columns={
                  stockData?.investorPicks.columns.length > 0
                    ? stockData.investorPicks.columns[0]
                    : []
                }
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  }

  return (
    <div>
      <Subscribe currentLanguage={currentLanguage} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            className="Btn-part"
            variant="outlined"
            onClick={() => {
              history.push('/live-data');
            }}
          >
            {t('gainButton')}
          </Button>
        </Grid>
      </Grid>
      <section className="home-main-section">
        <Box
          sx={{ my: 3 }}
          style={
            currentLanguage === 'ar'
              ? {
                textAlign: 'right',
              }
              : {
                textAlign: 'left',
              }
          }
        >
          <Typography className="section-header" variant="p">
            {t('heading')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                style={
                  currentLanguage === 'ar'
                    ? {
                      direction: 'rtl',
                    }
                    : {
                      direction: 'ltr',
                    }
                }
              >
                <Tab label={`${t('inventory')}`} value="1" />
                <Tab label={`${t('history')}`} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className="investor-tab">
              {tableMultiPanel()}
            </TabPanel>
            <TabPanel value="2" style={{ padding: '24px 0px' }}>
              <Box style={{ overflowX: 'scroll' }}>
                <CollapsibleDataTable
                  rows={stockData?.history.rows.length > 0 ? stockData.history.rows : []}
                  columns={stockData?.history.columns.length > 0 ? stockData.history.columns : []}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tranderValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box
                sx={{ my: 3 }}
                style={
                  currentLanguage === 'ar'
                    ? {
                      textAlign: 'right',
                    }
                    : {
                      textAlign: 'left',
                    }
                }
              >
                <Typography className="section-header" variant="p">
                  {t('subHeading')}
                </Typography>
              </Box>
              <TabList
                onChange={handleChangeTrander}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
                style={
                  currentLanguage === 'ar'
                    ? {
                      direction: 'rtl',
                    }
                    : {
                      direction: 'ltr',
                    }
                }
              >
                <Tab label={`${t('inventory')}`} value="3" />
                <Tab label={`${t('history')}`} value="4" />
              </TabList>
            </Box>
            <TabPanel value="3" className="investor-tab">
              {tableMultiPanel()}
            </TabPanel>
            <TabPanel value="4" style={{ padding: '24px 0px' }}>
              <Box style={{ overflowX: 'scroll' }}>
                <CollapsibleDataTable
                  rows={stockData?.history.rows.length > 0 ? stockData.history.rows : []}
                  columns={stockData?.history.columns.length > 0 ? stockData.history.columns : []}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </section>
    </div>
  );
}
export default Home;
