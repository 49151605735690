import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { localeList } from '../../../components/common';

function CountrySelect({ handleChange, country }) {
    return <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Occupation"
        placeholder="Select Country"
        className="country-select"
        onChange={handleChange}
        value={country}
        name="country"
    >
        <MenuItem disabled value="">
            Select Country
        </MenuItem>
        {localeList.map((item) => (
            <MenuItem key={item.value} value={item.value}>
                <div style={{ display: 'flex' }}>
                    <img src={item.image} alt={item.value} />
                    {item.label}
                </div>
            </MenuItem>
        ))}
    </Select>
}
export default CountrySelect;