import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import dashboardReducer from './reducer/reducer';
import authReducer from './reducer/authReducer';
import AdminUserReducer from './reducer/AdminUserReducer';

const rootReducer = combineReducers({
  stock: dashboardReducer,
  authData: authReducer,
  adminUser: AdminUserReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
