import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { UserService } from '../../../components/common';
import { useDispatch } from 'react-redux';
import { AddUserSubService } from '../../../redux/action/AdminUser';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { useTranslation } from 'react-i18next';
import { AdminAddFooter } from '../../../components/Admin/AdminAddFooter';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const AddService = () => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const history = useHistory();
  const [service, setService] = useState();
  const [loading, setLoading] = useState(false);
  const initialValue = {
    serviceName: "",
    subTerms: "",
    fees: "",
    discount: ""
  }
  const validationSchema = Yup.object().shape({
    serviceName: Yup.string().required('Service name required!'),
    subTerms: Yup.string().required('Subscription terms required!'),
    fees: Yup.string().required('Fees required!'),
  });
  const handleChangeValue = (handleChange) => e => {
    setService({ ...service, [e.target.name]: e.target.value })
    handleChange(e)
  }

  return (
    <div className="add-service-main">
      <div>
        <Typography className="section-header" variant="h5">
          <span>{t("add_user")}</span>
        </Typography>
        <p>{t("add_user_dec")}</p>
      </div>
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={async () => {
            await dispatch(AddUserSubService(service));
            setTimeout(() => {
              setLoading(false);
              history.push('/admin');
            }, 3000)
          }}
        >
          {({ errors, touched, values, handleChange }) => {
            return (
              <Form>
                <div>
                  <Grid item container spacing={1} justify="center">
                    <Grid item xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
                      <label>Select Service</label>
                      <FormControl fullWidth variant="outlined">
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Occupation"
                          placeholder="Select Service"
                          className="country-select"
                          name="serviceName"
                          onChange={handleChangeValue(handleChange)}
                        >
                          <MenuItem disabled value="">
                            <em>{t("select_service")}</em>
                          </MenuItem>
                          {UserService.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              <div style={{ display: 'flex' }}>{item.name}</div>
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.serviceName && touched.serviceName ? (
                          <div className="error-msg">{errors.serviceName}</div>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl className="input-field">
                    <FormLabel id="demo-controlled-radio-buttons-group" style={{ color: '#384955' }}>
                      {t("Subscription Terms")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="subTerms"
                      onChange={handleChangeValue(handleChange)}
                    >
                      <FormControlLabel value="Monthly" control={<Radio />} label={t("monthly")} />
                      <FormControlLabel value="Yearly" control={<Radio />} label={t("yearly")} />
                    </RadioGroup>
                    {errors.subTerms && touched.subTerms ? (
                      <div className="error-msg">{errors.subTerms}</div>
                    ) : null}
                  </FormControl>
                  <Grid item container spacing={1} justify="center">
                    <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                      <label>{t("Fees")}</label>
                      <TextField variant="outlined" onChange={handleChangeValue(handleChange)} fullWidth name="fees" type="text" />
                      {errors.fees && touched.fees ? (
                        <div className="error-msg">{errors.fees}</div>
                      ) : null}
                    </Grid>
                    <Grid item sx={12} xs={6} sm={6} md={6} className="input-field">
                      <label>{t("discount")}</label>
                      <TextField variant="outlined" fullWidth name="discount" type="text" />
                    </Grid>
                  </Grid>
                </div>
                {AdminAddFooter(loading)}
              </Form>
            );
          }}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AddService;
