import { DIRECTION } from '../../utils/constants';

/**
 * Class to configure environment settings
 */
class EnvironmentConfig {
  TOKEN = 'token';
  _currentUser = null;
  _language = 'en';
  _direction = DIRECTION.LTR;
  _isAuthenticated = false;

  /**
   * Returns the current logged in user
   */
  get currentUser() {
    return this._currentUser || null;
  }

  /**
   * Sets the current logged in user
   * @param  {object} user
   */
  set currentUser(val) {
    this._currentUser = val || null;
  }

  get language() {
    return this._language;
  }

  set language(val) {
    this._language = val;
  }

  get direction() {
    return this._direction || '';
  }

  set direction(val) {
    this._direction = val;
  }

  set isAuthenticated(val) {
    this._isAuthenticated = val;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }
}

export default new EnvironmentConfig();
