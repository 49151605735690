import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Box, Tab, Typography } from '@mui/material';
import PersonPinIcon from '@mui/icons-material/PersonOutline';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import AccountBalance from '@mui/icons-material/AccountBalance';
import ThumbUpOffAlt from '@mui/icons-material/ThumbUpOffAlt';
import { GetAdmin, GetMarketInvestor, GetMarketUser, GetSubService } from '../../../redux/action/AdminUser';
import AdminUserTab from './AdminUserTab';
import AdminSubTab from './AdminSubTabs';
import AdminMarketTab from './AdminMarketTab';
import './AdminPortal.scss';
import { useTranslation } from 'react-i18next';

const AdminPortal = ({ currentLanguage }) => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const history = useHistory();

  const [adminTab, setAdminTab] = useState('users');
  const [subscribeTab, setSubscribeTab] = useState('subMarket');
  const [userSubscription, setUserSubscription] = useState('activeSub');
  const [marketRecommTab, setMarketRecommTab] = useState('investor');

  const handleChange = (event, newValue) => {
    setAdminTab(newValue);
  };

  const handleChangeSubTab = (event, newValue) => {
    setSubscribeTab(newValue);
  };

  const handleChangeUserSub = (e, newValue) => {
    setUserSubscription(newValue);
  };

  const handleChangeMarketTab = (e, newValue) => {
    setMarketRecommTab(newValue);
  };

  useEffect(() => {
    if (!localStorage.getItem("isAdmin")) {
      history.push('/adminlogin')
    } else {
      dispatch(GetAdmin());
      dispatch(GetMarketUser());
      dispatch(GetMarketInvestor());
      dispatch(GetSubService());
    }
  }, []);

  return (
    <div className="admin-portal">
      <Box sx={{ my: 3 }} style={currentLanguage === 'ar'
        ? {
          direction: 'rtl',
        }
        : {
          direction: 'ltr',
        }}>
        <Typography className="section-header" variant="h4">
          {t("admin_portal")}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={adminTab}>
          <Box sx={{ background: '#2596BE', color: '#fff' }} style={currentLanguage === 'ar'
            ? {
              direction: 'rtl',
            }
            : {
              direction: 'ltr',
            }}>
            <TabList
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
            >
              <Tab className="tab-view" icon={<PersonPinIcon />} label={t("users")} value="users" />
              <Tab className="tab-view" icon={<CurrencyExchange />} label={t("subscription")} value="subs" />
              <Tab className="tab-view" icon={<AccountBalance />} label={t("payment")} value="payment" />
              <Tab
                className="tab-view"
                icon={<ThumbUpOffAlt />}
                label={t("market_recom")}
                value="adminMarket"
              />
            </TabList>
          </Box>
          <TabPanel value="users" style={{ padding: '24px 0px' }}>
            <AdminUserTab currentLanguage={currentLanguage} />
          </TabPanel>
          <TabPanel value="subs" style={{ padding: '24px 0px' }} className="subscription-tab">
            <AdminSubTab currentLanguage={currentLanguage} subscribeTab={subscribeTab} handleChangeSubTab={handleChangeSubTab} userSubscription={userSubscription} handleChangeUserSub={handleChangeUserSub} />
          </TabPanel>
          <TabPanel value="payment" style={{ padding: '24px 0px' }}>
            <Box style={{ overflowX: 'scroll' }}>tab3</Box>
          </TabPanel>
          <TabPanel value="adminMarket" style={{ padding: '24px 0px' }} className="subscription-tab">
            <AdminMarketTab currentLanguage={currentLanguage} marketRecommTab={marketRecommTab} handleChangeMarketTab={handleChangeMarketTab} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
export default AdminPortal;
