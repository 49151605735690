import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableCellLogo from '../../assets/images/table-cell.svg';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { confirmLogin, deleteAdminUsers } from '../../redux/action/AdminUser';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export function TableRows({ columns, rows, page, rowsPerPage, type }) {
    const { t } = useTranslation("admin");
    const history = useHistory();
    const [openDelete, setOpenDelete] = useState(false);
    const [adminUser, setAdminUser] = useState();
    const dispatch = useDispatch();

    const handleOpen = (item) => {
        history.push(`/admin/add-edit-admin/${item._id}`)
    }

    const deleteUser = (row) => {
        setAdminUser(row)
        setOpenDelete(true)
    }

    const updateLogin = async (row) => {
        const data = {
            id: row?._id,
            userLogin: !row?.userLogin
        }
        await dispatch(confirmLogin(data))
        history.push("/admin");
    }

    const deleteAdminUser = async () => {
        let data = {
            id: adminUser?._id
        }
        await dispatch(deleteAdminUsers(data))
        history.push("/admin");
        setOpenDelete(false);
    }

    const values = (value, column) => {
        if (column?.image) {
            return <Box sx={{ display: 'flex' }}>
                <img
                    style={{ height: '20px', width: '20px', marginRight: '3px' }}
                    src={TableCellLogo}
                    alt="cell logo"
                />
                {value}
            </Box>
        } else if (typeof value === "object") {
            return <div className='exchange-title'>
                {value.map((d) => <span>{d}</span>)}
            </div>
        } else if (column?.id === "subTerms") {
            return <div className='sub-terms'>{t(value)}</div>
        } else {
            return <>{t(value)}</>
        }
    }

    return (
        <>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns?.map((column) => (
                            <TableCell key={column.id} align={column.align}>
                                {t(column.label)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                {columns?.map((column) => {
                                    const value = row?.[column?.id];
                                    return (
                                        <TableCell key={column?.id} align={column?.align} style={{ color: column.color }}>
                                            {values(value, column)}
                                            {
                                                column?.id === 'action' &&
                                                <Box className='action-part'>
                                                    <ModeEditIcon className="edit-user" onClick={() => { handleOpen(row) }} />
                                                    <DeleteIcon className="delete-user" onClick={() => { deleteUser(row) }} />
                                                    {
                                                        row?.userLogin ?
                                                            <PersonIcon className='hide-user' onClick={() => { updateLogin(row) }} />
                                                            : <PersonOffIcon className='hide-user' onClick={() => { updateLogin(row) }} />
                                                    }
                                                </Box>
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <Box sx={{ borderTop: 3, color: 'red' }}>
                    <DialogTitle sx={{ color: 'black', backgroundColor: 'gainsboro' }}>
                        Delete User
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText color='black' >
                            {/* <Warning fontSize='large' color='error' id='warning' /> */}
                            Are you sure you want to delete the user ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button variant='contained' onClick={() => deleteAdminUser()} autoFocus>
                            Yes
                        </Button>
                        <Button variant='outlined' onClick={() => setOpenDelete(false)}>No</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>)
}
