import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { LoginAdmin } from '../../../redux/action/AdminUser';
import { CustomInput } from '../../../components/CustomInput';
import Loader from '../../../components/Loader/Loader';
import { eyeIcon, handleChangePassword } from '../../../components/common';
import PasswordValidation from '../Auth/PasswordValidation';
import { useTranslation } from 'react-i18next';
import AuthHeader from '../../../components/Layout/AuthHeader';

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

const initialValye = {
  email: '',
  password: "",
};

const AdminLogin = (props) => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const dispatch = useDispatch();
  const adminUser = useSelector((state) => state.adminUser);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const validationSchema = Yup.object().shape({
    // username: Yup.string().required("Full name required!"),
    password: Yup.string()
      .required(t("pass_required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, One Special Case Character',
      ),
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
  });

  useEffect(() => {
    setTimeout(() => {
      if (adminUser?.adminUser?.[0]?.role === 'admin') {
        setLoading(false);
        history.push('/admin');
      }
    }, 3000)
  }, [adminUser]);


  const handleChangeAcco = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={
      props.currentLanguage === 'ar'
        ? {
          direction: 'rtl',
        }
        : {
          direction: 'ltr',
        }
    }>
      <AuthHeader title={t("welcome_admin")} description={t("login_description")} />
      <Box>
        <Formik
          initialValues={initialValye}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            dispatch(LoginAdmin(values));
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 3000)
          }}
        >
          {({ errors, touched, values, handleChange }) => {
            return (
              <>
                <Form>
                  <div className="login-form form-group">
                    <Grid item container spacing={1} justify="center">
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <CustomInput
                          name="email"
                          type="email"
                          value={values.email}
                          touched={touched.email}
                          errors={errors.email}
                          placeholder={t("enter_email")}
                        />
                      </Grid>
                      <Grid item sx={12} xs={12} sm={12} md={12} className="input-field">
                        <div style={{ position: 'relative' }}>
                          <Field
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            placeholder={t("enter_pass")}
                            onChange={handleChangePassword(handleChange)}
                            className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''
                              }`}
                            id="psw"
                            lang={props.currentLanguage}
                          />
                          {eyeIcon(props.currentLanguage, showPassword, setShowPassword)}
                        </div>
                        {errors.password && touched.password ? (
                          <PasswordValidation currentLanguage={props.currentLanguage} expanded={expanded} handleChangeAcco={handleChangeAcco} />
                        ) : null}
                      </Grid>
                    </Grid>
                    <Button className="login-btn" type="submit" fullWidth>
                      {loading ? <Loader /> : t("login")}
                    </Button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;
