import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone'
import { Button, Grid } from '@mui/material';
import { Exchange } from '../../../components/common';
import upload from '../../../assets/images/Admin/upload.svg';
import { AddUserMarketInvestor, GetMarketInvestor } from '../../../redux/action/AdminUser';
import CountrySelect from './CountrySelect';
import { useTranslation } from 'react-i18next';

const AddInvestors = (props) => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const [marketInvestorData, setMarketInvestorData] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMarketInvestorData({ ...marketInvestorData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(AddUserMarketInvestor(marketInvestorData));
    props.setOpenAddMarket(false);
    dispatch(GetMarketInvestor());
  };
  return (
    <div className="add-market-main">
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ display: "flex", position: "relative" }}>
            <div className='step-main'>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <span className='border-left'></span>
          </div>
          <div>
            <Grid item container spacing={1} justify="center">
              <Grid item xs={12} sm={12} md={12}>
                <label>{t("select_country")}</label>
                <FormControl fullWidth variant="outlined">
                  <CountrySelect country={marketInvestorData?.country} handleChange={handleChange} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <label>{t("select_market")}</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Occupation"
                    placeholder="Select Exchange"
                    className="country-select"
                    onChange={handleChange}
                    value={marketInvestorData?.market}
                    name="market"
                  >
                    <MenuItem disabled value="">
                      {t("select_market")}
                    </MenuItem>
                    {Exchange.map((item) => (
                      <MenuItem key={item} value={item}>
                        <div style={{ display: 'flex' }}>{item}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <label>{t("select_exchange")}</label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Occupation"
                    placeholder="Select Exchange"
                    className="country-select"
                    onChange={handleChange}
                    value={marketInvestorData?.exchange}
                    name="exchange"
                  >
                    <MenuItem disabled value="">
                      Select Exchange
                    </MenuItem>
                    {Exchange.map((item) => (
                      <MenuItem key={item} value={item}>
                        <div style={{ display: 'flex' }}>{item}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <img src={upload} alt="upload" />
                        <p style={{ margin: "7px 0px" }}>{t("upload_data")}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid item container spacing={1} style={{ position: 'relative', bottom: '-7em' }}>
          <Grid item sx={12} xs={6} sm={6} md={6}>
            <Button variant="outlined" onClick={() => props.setOpenAddMarket(false)} className="cancel-btn">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item sx={12} xs={6} sm={6} md={6}>
            <Button variant="outlined" type="submit" className="save-btn">
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default AddInvestors;
