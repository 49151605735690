import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminColumn, currentLanguages } from '../../../components/common';
import { SearchInput } from '../../../components/Search/SearchInput';
import DataTable from '../../../components/Table/DataTable';

const AdminUserTab = ({ currentLanguage }) => {
    const { t } = useTranslation('admin');
    const adminData = useSelector((state) => state.adminUser);
    const [adminUser, setAdminUser] = useState()

    useEffect(() => {
        const data = adminData?.adminData?.filter((item) => {
            if (item?.role !== 'admin') {
                return item
            }
        })
        setAdminUser(data)
    }, [adminData])

    const history = useHistory();
    return <>
        <div className="search-part" style={currentLanguages(currentLanguage)}>
            <SearchInput />
        </div>
        <div className="add-new-admin" onClick={() => history.push('/admin/add-edit-admin')}>
            <p>+ {t("add_new")}</p>
        </div>
        <div style={{ overflowX: 'scroll', marginTop: '30px' }}>
            <DataTable
                action={true}
                rows={adminUser !== undefined ? adminUser : []}
                columns={adminColumn?.length > 0 ? adminColumn : []}
            />
        </div></>
}
export default AdminUserTab;