import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Routers from '../../router';
import { styled } from '@mui/material/styles';
import EnvConfig from '../../services/helpers/envConfig';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../../components/Layout/Header';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { SlideBar, DrawerHeader } from '../../components/Layout/SlideBar';
import { TranslationServiceHelper } from "../../HOC/TranslationServiceHelper";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2)
}));
const MainPanel = props => {

  const locationPath = props && props.location && props.location.pathname;

  const [open, setOpen] = useState(false);
  const [path, setPath] = useState();

  useEffect(() => {
    setPath(props?.location?.pathname)
    const pathNameTerms = locationPath.includes("/live-data");
    const pathNameHome = locationPath.includes("/");
    if (pathNameTerms) {
      props.loadNameSpaces("live-data");
    } else if (pathNameHome) {
      props.loadNameSpaces("home");
    }
  }, [props.location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLanguageChange = e => {
    const { setEntityPreferredLocale } = props;
    setEntityPreferredLocale(e.target.value);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const pathNames = path === "/login" || path === "/register" || path === "/verify" || path?.includes("/resetpass") || path === "/forgotlink" || path === "/adminlogin";
  return (
    <div style={{ direction: EnvConfig.direction }}>
      <CssBaseline />
      <div>
        {pathNames ? '' :
          <>
            <Header open={open} handleDrawerOpen={() => handleDrawerOpen()} currentLanguage={props.currentLanguage} />
            <SlideBar open={open} handleDrawerClose={() => handleDrawerClose()} handleLanguageChange={handleLanguageChange} localList={props.localeList} currentLanguage={props.currentLanguage} />
          </>}
        <div style={{ width: '100%', height: '100%' }}>
          <Main open={open} className={pathNames ? 'auth-main' : ''}>
            {pathNames ? '' : <DrawerHeader />}
            <Routers currentLanguage={props.currentLanguage} />
          </Main>
        </div>
      </div>
    </div>
  );
}

MainPanel.propTypes = {
  isTReady: PropTypes.bool.isRequired,
  hasNameSpaceLoaded: PropTypes.func.isRequired,
  loadNameSpaces: PropTypes.func.isRequired
};

export default compose(
  withRouter,
  TranslationServiceHelper
)(MainPanel);