export const INVESTORPICKS = 'INVESTORPICKS';
export const HISTORY = 'HISTORY';
export const TRADERPICKS = 'TRADERPICKS';
export const TOPGAIN = 'TOPGAIN';
export const TOPLOSS = 'TOPLOSS';
export const SUBHISTORY = 'SUBHISTORY';
export const ADMINDATA = 'ADMINDATA';
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const ADD_MARKET_USER = 'ADD_MARKET_USER';
export const MARKETDATA = 'MARKETDATA';
export const ADD_USER = 'ADD_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";
export const USER_CONFIRM = 'USER_CONFIRM';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CREATE_MARKET_INVESTOR = 'CREATE_MARKET_INVESTOR';
export const GET_MARKET_INVESTOR = 'GET_MARKET_INVESTOR';
export const FORGOT_LINK = 'FORGOT_LINK';
export const RESET_PASS = 'RESET_PASS';
export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
export const UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER';
export const ADMIN_LOGIN_CONFIRM = 'ADMIN_LOGIN_CONFIRM';
export const VERIFY_OTP = "VERIFY_OTP"; 
export const RESET_OTP = "RESET_OTP";
export const FORGOT_TOKEN_CHECK = "FORGOT_TOKEN_CHECK" ;
export const CREATE_SUB_SERVICE = "CREATE_SUB_SERVICE";
export const GET_SUB_SERVICE = "GET_SUB_SERVICE";