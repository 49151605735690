import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Logo from '../../assets/images/logo.svg';
import { Box } from '@material-ui/core';

function AuthHeader(props) {
    const history = useHistory();
    const pathName = history?.location?.pathname;
    return (
        <Box className="login-page">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                className="header-muhnak"
            >
                <Typography align="center" variant="h6" noWrap component="div">
                    <img src={Logo} alt="logo" onClick={() => history.push('/')} />
                </Typography>
                <Box
                    className="header-text"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {
                        (pathName === "/login" || pathName === "/register") && <>
                            <div className="signin pointer" onClick={() => history.push('/login')}>{props.signin}</div>
                            <div className="signup pointer" onClick={() => history.push('/register')}>{props.signup}</div>
                        </>
                    }

                </Box>
            </Box>
            <Box>
                <div className="login-welcome">
                    <div>{props.title}</div>
                    <p>{props.description}</p>
                </div>
            </Box>
        </Box>
    );
}

export default AuthHeader;
