import axios from 'axios';
import { API_BASE_URL, GRPHQL_BASE_URL } from '../utils/constants';

export const getApi = async (url, params) => {
  const config = {
    params: params,
  };
  try {
    const { data } = await axios.get(`${API_BASE_URL}${url}`, config);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const postApi = async (url, body) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}${url}`, body);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const putApi = async (url, body) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}${url}`, body);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const deleteApi = async (url, params) => {
  const config = {
    params: params,
  };
  try {
    const { data } = await axios.delete(`${API_BASE_URL}${url}`, config);
    console.log("data", data);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const getGraphqlApi = async (url, params) => {
  const config = {
    params: params,
  };
  try {
    const { data } = await axios.get(`${GRPHQL_BASE_URL}${url}`, config);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};
