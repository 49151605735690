import { toast } from "react-toastify";
import { postApi, putApi } from "../../services/apiCall.service";
import { ADD_USER, FORGOT_LINK, FORGOT_TOKEN_CHECK, LOGIN_USER, RESET_OTP, RESET_PASS, UPDATE_PROFILE, USER_CONFIRM, VERIFY_OTP } from "../type";

function AddUser(data) {
    return (dispatch) => {
        postApi(`user`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: ADD_USER, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function LoginUser(data) {
    return (dispatch) => {
        postApi(`login`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: LOGIN_USER, payload: res.data });
                    localStorage.setItem('userData', JSON.stringify(res.data));
                    localStorage.removeItem('adminUser');
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function confirmUser(data) {
    return (dispatch) => {
        putApi(`confirm`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: USER_CONFIRM, payload: res.data });
                    localStorage.setItem('isLogin', true);
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}
function forgotUser(data) {
    return (dispatch) => {
        postApi(`forgotLink`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: FORGOT_LINK, payload: res.data });
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function forgotCodeCheck(data) {
    return (dispatch) => {
        postApi(`forgot-token-check`, data)
            .then((res) => {
                console.log("res.success", res);
                if (res.success) {
                    dispatch({ type: FORGOT_TOKEN_CHECK, payload: res.data });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function resetPasswordUser(data) {
    return (dispatch) => {
        putApi(`resetPassword`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: RESET_PASS, payload: res.data });
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function UpdateProfileUser(data) {
    return (dispatch) => {
        putApi(`update-profile`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: UPDATE_PROFILE, payload: res.data });
                    toast(res.message);
                    localStorage.setItem('userData', JSON.stringify({ ...res.data, id: res.data._id }));
                    localStorage.setItem("updateProfile", true);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function verifyOtp(data) {
    return (dispatch) => {
        postApi(`verifyOtp`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: VERIFY_OTP, payload: res.data });
                    localStorage.setItem("verifyUser", true);
                    localStorage.setItem('isLogin', true);
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }
}

function resendOtp(data) {
    console.log("data", data);
    return (dispatch) => {
        postApi(`resendOtp`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: RESET_OTP, payload: res.data });
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }
}

export {
    AddUser,
    LoginUser,
    confirmUser,
    forgotUser,
    resetPasswordUser,
    UpdateProfileUser,
    verifyOtp,
    resendOtp,
    forgotCodeCheck
}