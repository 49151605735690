import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

export function SearchInput() {
    
    const { t } = useTranslation('liveData');

    return (
        <>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    boxShadow: 'none',
                    border: '1px solid #DCDEE1',
                }}
            >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={t('searchMarket')}
                    inputProps={{ 'aria-label': `${t('searchMarket')}` }}
                />
            </Paper>
        </>
    );
}
