import {
  TOPGAIN,
  TOPLOSS,
  TRADERPICKS,
  INVESTORPICKS,
  HISTORY,
  SUBHISTORY,
} from '../type';

let state_data = {
  investorPicks: {
    columns: [],
    rows: [],
  },
  history: {
    columns: [],
    rows: [],
  },
  subHistory: {
    columns: [],
    rows: [],
  },
  traderPicks: {
    columns: [],
    rows: [],
  },
  topGain: {
    nasdaq: {
      columns: [],
      rows: [],
    },
    nyse: {
      columns: [],
      rows: [],
    },
  },
  topLoss: {
    nasdaq: {
      columns: [],
      rows: [],
    },
    nyse: {
      columns: [],
      rows: [],
    },
  },
  admin: {
    columns: [],
    rows: [],
  },
};

const dashboardReducer = (state = state_data, action) => {
  switch (action.type) {
    case INVESTORPICKS:
      let investorPicks = action.payload;
      return { ...state, investorPicks };
    case HISTORY:
      let history = action.payload;
      return { ...state, history };
    case SUBHISTORY:
      let subHistory = action.payload;
      return { ...state, subHistory };
    case TRADERPICKS:
      let traderPicks = action.payload;
      return { ...state, traderPicks };
    case TOPGAIN:
      let topGain = action.payload;
      return { ...state, topGain };
    case TOPLOSS:
      let topLoss = action.payload;
      return { ...state, topLoss };
    default:
      return state;
  }
};

export default dashboardReducer;
