import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { TableRows } from './TableRows';

export default function MultiheaderTable(props) {
  const { rows, columns } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className="multiheader-table">
      <TableContainer>
        <div className="multi-header">
          <div className="header-first">
            <TableHead>Recommendations Details</TableHead>
          </div>
          <div className="header-first">
            <TableHead>Stock Current Price Details</TableHead>
          </div>
        </div>
        <TableRows columns={columns} rows={rows} rowsPerPage={rowsPerPage} page={page} />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
