import React, { Suspense } from 'react';
import MainPanel from './view/App/App';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TranslationServiceProvider } from './providers/TranslationServiceProvider';
import { createHashHistory } from 'history';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/store';

const BrowserHistory = createHashHistory();

function App() {
  const { ready } = useTranslation(undefined, { useSuspense: false });
  return (
    <>
      {!ready ? (
        ''
      ) : (
        <Provider store={store}>
          <Suspense fallback="Loading....">
            <TranslationServiceProvider>
              <Router history={BrowserHistory}>
                <MainPanel />
              </Router>
            </TranslationServiceProvider>
          </Suspense>
        </Provider>
      )}
    </>
  );
}

export default App;
