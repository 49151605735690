import { toast } from "react-toastify";
import { getApi, postApi, putApi, deleteApi } from "../../services/apiCall.service";
import { ADD_ADMIN_USER, ADD_MARKET_USER, ADMINDATA, ADMIN_LOGIN_CONFIRM, CREATE_MARKET_INVESTOR, CREATE_SUB_SERVICE, DELETE_ADMIN_USER, GET_MARKET_INVESTOR, GET_SUB_SERVICE, LOGIN_ADMIN, MARKETDATA, UPDATE_ADMIN_PROFILE, UPDATE_ADMIN_USER } from "../type";

function LoginAdmin(data) {
    return (dispatch) => {
        postApi(`admin-login`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: LOGIN_ADMIN, payload: res.data });
                    localStorage.setItem('adminUser', JSON.stringify(res.data[0]));
                    localStorage.removeItem('userData');
                    localStorage.setItem('isLogin', true);
                    if (res.data && res.data[0] && res.data[0]?.role) {
                        localStorage.setItem('isAdmin', true);
                    } else {
                        localStorage.setItem('isAdmin', false);
                    }
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function UpdateAdminProifle(data) {
    return (dispatch) => {
        postApi(`update-admin-profile`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: UPDATE_ADMIN_PROFILE, payload: res.data });
                    toast(res.message);
                    localStorage.setItem('adminUser', JSON.stringify(res.data));
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function GetAdmin() {
    return (dispatch) => {
        getApi(`admin-user`, '')
            .then((res) => {
                if (res.success) {
                    dispatch({ type: ADMINDATA, payload: res.data });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function AddAdminUsers(data) {
    return (dispatch) => {
        postApi(`admin-user`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: ADD_ADMIN_USER, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}
function UpdateAdminUsers(data) {
    return (dispatch) => {
        putApi(`update-user`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: UPDATE_ADMIN_USER, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}
function confirmLogin(data) {
    return (dispatch) => {
        putApi(`login-confirm`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: ADMIN_LOGIN_CONFIRM, payload: res.data });
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}
function deleteAdminUsers(data) {
    return (dispatch) => {
        deleteApi(`delete-user/${data.id}`, "")
            .then((res) => {
                if (res.success) {
                    dispatch({ type: DELETE_ADMIN_USER, payload: res.data });
                    toast(res.message)
                }
                else {
                    toast(res.message)
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}
function GetMarketUser() {
    return (dispatch) => {
        getApi(`sub-market-user`, '')
            .then((res) => {
                if (res.success) {
                    dispatch({ type: MARKETDATA, payload: res.data });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function AddUserMarket(data) {
    return (dispatch) => {
        postApi(`sub-market-user`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: ADD_MARKET_USER, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function GetMarketInvestor() {
    return (dispatch) => {
        getApi(`market-investor`, '')
            .then((res) => {
                if (res.success) {
                    dispatch({ type: GET_MARKET_INVESTOR, payload: res.data });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function AddUserMarketInvestor(data) {
    return (dispatch) => {
        postApi(`market-investor`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: CREATE_MARKET_INVESTOR, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function GetSubService() {
    return (dispatch) => {
        getApi(`sub-service`, '')
            .then((res) => {
                if (res.success) {
                    dispatch({ type: GET_SUB_SERVICE, payload: res.data });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

function AddUserSubService(data) {
    return (dispatch) => {
        postApi(`sub-service`, data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: CREATE_SUB_SERVICE, payload: res.data });
                    toast(res.message);
                } else {
                    toast(res.message);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
}

export {
    GetAdmin,
    AddAdminUsers,
    AddUserMarket,
    GetMarketUser,
    GetMarketInvestor,
    AddUserMarketInvestor,
    LoginAdmin,
    UpdateAdminUsers,
    confirmLogin,
    deleteAdminUsers,
    GetSubService,
    AddUserSubService,
    UpdateAdminProifle
}