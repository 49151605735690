import { Box, Tab } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { marketColunmn, subServiceColunmn } from '../../../components/common';
import DataTable from '../../../components/Table/DataTable';

const AdminSubTab = ({ currentLanguage, subscribeTab, handleChangeSubTab, userSubscription, handleChangeUserSub }) => {
    const { t } = useTranslation('admin');
    const adminData = useSelector((state) => state.adminUser);
    const history = useHistory();
    return <>
        <Box style={{ overflowX: 'scroll' }} >
            <TabContext value={subscribeTab}>
                <div style={currentLanguage === 'ar'
                    ? {
                        direction: 'rtl',
                    }
                    : {
                        direction: 'ltr',
                    }}>
                    <TabList
                        onChange={handleChangeSubTab}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        <Tab className="tab-view" label={t("markets")} value="subMarket" />
                        <Tab className="tab-view" label={t("service")} value="service" />
                        <Tab className="tab-view" label={t("user_sub")} value="userSub" />
                    </TabList>
                </div>
                <TabPanel value="subMarket" style={{ padding: '24px 0px' }}>
                    <div className="add-new-admin" onClick={() => history.push('/add-market')}>
                        <p>+ {t("add_new")}</p>
                    </div>
                    <div style={{ overflowX: 'scroll', marginTop: '30px' }}>
                        <DataTable
                            rows={
                                adminData?.marketData !== undefined && adminData?.marketData?.length > 0
                                    ? adminData?.marketData
                                    : []
                            }
                            columns={marketColunmn?.length > 0 ? marketColunmn : []}
                        />
                    </div>
                </TabPanel>
                <TabPanel value="service" style={{ padding: '24px 0px' }}>
                    <div className="add-new-admin" onClick={() => history.push('/add-service')}>
                        <p>+ {t("add_new")}</p>
                    </div>
                    <div style={{ overflowX: 'scroll', marginTop: '30px' }}>
                        <DataTable
                            rows={adminData?.getSubService !== undefined && adminData?.getSubService?.length > 0
                                ? adminData?.getSubService
                                : []}
                            columns={subServiceColunmn?.length > 0 ? subServiceColunmn : []}
                        />
                    </div>
                </TabPanel>
                <TabPanel value="userSub" style={{ padding: '24px 0px' }}>
                    <div style={{ marginBottom: '30px' }} className={`userSubTabsbtn `}>
                        <ToggleButtonGroup
                            color="primary"
                            value={userSubscription}
                            exclusive
                            onChange={handleChangeUserSub}
                            className="toggle-group"
                        >
                            <ToggleButton value="activeSub" className="toggle-btn">
                                Active Subscription
                            </ToggleButton>
                            <ToggleButton value="inactiveSub" className="toggle-btn">
                                Inactive Subscriptions
                            </ToggleButton>
                            <ToggleButton value="pendingPayment" className="toggle-btn">
                                Pending Payment
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div style={{ overflowX: 'scroll', marginTop: '30px' }}>
                        <DataTable
                            rows={adminData?.adminData?.rows?.length > 0 ? adminData?.adminData.rows : []}
                            columns={adminData?.adminData?.columns?.length > 0 ? adminData?.adminData.columns : []}
                        />
                    </div>
                </TabPanel>
            </TabContext>
        </Box>
    </>
}
export default AdminSubTab;