import * as React from 'react';
import { Field } from 'formik';

export function CustomInput(props) {
    return (
        <>
            <label>{props.label}</label>
            <Field
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                className={`form-control ${props.touched && props.errors ? 'is-invalid' : ''
                    }`}
            />
            {props.errors && props.touched ? (
                <div className="error-msg">{props.errors}</div>
            ) : null}
        </>
    );
}
