import * as React from 'react';
import './Search.scss';
import Sync from '../../assets/images/syncNew.svg';
import Button from '@mui/material/Button';
import { SearchInput } from './SearchInput';

export function Search({ currentLanguage }) {
  return (
    <div
      className="search-part"
      style={
        currentLanguage === 'ar'
          ? {
            direction: 'rtl',
          }
          : {
            direction: 'ltr',
          }
      }
    >
      <SearchInput />
      <Button className="syncButton" variant="contained">
        <img src={Sync} alt="sync" />
      </Button>
    </div>
  );
}
