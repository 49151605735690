import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { confirmUser } from '../../../redux/action/Auth';
import { useDispatch } from 'react-redux';
import './style.scss'
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import Loader from '../../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

const EmailConfirm = () => {
    const { t } = useTranslation("auth");
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    // CALL IT ONCE IN YOUR APP
    if (typeof window !== 'undefined') {
        injectStyle();
    }

    useEffect(() => {
        const id = history.location.pathname.split("/")[2];
        const data = {
            isConfirm: true,
            id: id
        }
        dispatch(confirmUser(data))
        localStorage.setItem('isLogin', true);
    })

    const handleGo = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            history.push("/home");
        }, 3000);
    }

    return (
        <>
            <div className='email-confirm'>
                <h2>{t("email_confirm")}</h2>
                <Button color='primary' variant='contained' onClick={() => handleGo()} >{loading ? <Loader /> : t("go_home")}</Button>
            </div>
            <ToastContainer />
        </>
    )
}
export default EmailConfirm;
